export { useTagApi } from './tag';
export { useAuthApi } from './auth';
export { useUserApi } from './user';
export { useDashboardApi } from './dashboard';
export { useDeviceApi } from './device';
export { useAdminApi } from './admin';
export { useWeatherApi } from './weather';
export { withUseQuery } from './withUseQuery';

export type ApiReturnType<T extends (...args: any) => any> = Awaited<ReturnType<T>>[0];

export { InputType } from '@wisionmonorepo/wision-device-service/src/features/legacy/inputs/model';
export type { Session } from '@wisionmonorepo/wision-core-service/src/core/model/session';
export type { Sessions } from '@wisionmonorepo/wision-core-service/src/core/model/dto/session';
export type { LoginResponse } from '@wisionmonorepo/api-client-v2/src/responses';
export type { UserProfile } from '@user-service/features/userProfile/model/userProfile';
export type { ChannelOptions } from '@wisionmonorepo/wision-device-service/src/features/common/options/model';
export type { Organization } from '@user-service/features/organization/model';
export type { UserSession } from '@user-service/features/userSession/model';
export type { DeviceBase } from '@wisionmonorepo/wision-device-service/src/features/common/device/model';
export type { DeviceOptions, AnalogOptions, SwitchOptions, PTOptions } from '@wisionmonorepo/wision-device-service/src/features/common/options/model';
export type { DeviceSettings } from '@wisionmonorepo/wision-device-service/src/features/legacy/settings/model';
export type { Widget } from '@wisionmonorepo/wision-user-service/src/features/widget/model';
export type { Dashboard, LinkGroupMessage } from '@wisionmonorepo/wision-user-service/src/features/dashboard/model';
export type { DeviceFilter, SelectedFilters, FilterCategory } from '@wisionmonorepo/wision-device-service/src/features/common/filter/model';
export type { DataResponse } from '@wisionmonorepo/wision-device-service/src/features/common/data/model';
export type { DataResponse as DataResponseV3 } from '@wisionmonorepo/wision-device-service/src/features/common/v3/data/model';
export type { Operation, Status as OperationStatus } from '@wisionmonorepo/wision-device-service/src/features/common/coreOperation/model';
export type { Radar } from '@wisionmonorepo/wision-device-service/src/features/legacy/radar/model';
export type { Marker, GetAllMarksResult as MarkersResponse } from '@wisionmonorepo/wision-device-service/src/features/legacy/marker/model';
export type { Alarm } from '@wisionmonorepo/wision-device-service/src/features/common/alarm/model';
export type { AlarmActionListEntry } from '@wisionmonorepo/wision-device-service/src/features/legacy/alarmAction/model';
export type {
  BillingInfo,
  ContactInfo
} from '@admin-service/features/organization/model';
export type { Group, EditGroup, CreateGroup } from '@wisionmonorepo/wision-device-service/src/features/common/groups/model';
export type { Status } from '@wisionmonorepo/wision-device-service/src/features/common/device/model';
