import { DashboardFilterSettings, textSearch } from '@/core/filter';
import { RootState } from '@/core/store/types';
import { GetterTree } from 'vuex';

export default <GetterTree<RootState, RootState>>{
  dateInterval(state: RootState): number {
    return state.persistent.dateInterval;
  },
  filterSettings(state: RootState): DashboardFilterSettings {
    const filterSettings = {};
    for (const key of Object.keys(state.persistent.filterSettings)) {
      filterSettings[key] = state.persistent.filterSettings[key];
      filterSettings[key].textSearch = state.transient.textSearch[key] || '';
    }
    return filterSettings;
  },
  hiddenWidgets(state: RootState): (view: string) => string[] {
    return (view: string): string[] => state.persistent.widgetsHidden[view] || [];
  },
  textSearch(state: RootState): textSearch {
    return state.transient.textSearch;
  },
};
