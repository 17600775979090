/**
 * @overview User DTO model
 */

import { UserProfile } from '@wisionmonorepo/wision-user-service/src/features/userProfile/model';

export type Location = [ number, number ];

export const defaultLocation: Location = [0, 0];

export interface Notification {
  desktop: boolean;
  email: boolean;
}

export const DEFAULT_NOTIFICATION: Notification = {
  email: false,
  desktop: false
};

type TimeFormat = '12h' | '24h';

export interface TimeConfig {
  timeFormat: TimeFormat;
  timezone: string;
  dateFormat: DateFormat;
}

export type DateFormat = 'MMM DD YYYY' | 'DD MMM YYYY';

export enum DateFormats {
  mmmddyyyy = 'MMM DD YYYY',
  ddmmmyyyy = 'DD MMM YYYY',
}

export const DEFAULT_TIME_CONFIG: TimeConfig = {
  timeFormat: '24h',
  timezone: UserProfile.DEFAULT_TIMEZONE,
  dateFormat: UserProfile.DEFAULT_DATE_FORMAT
};

export interface UserDTO {
  username: string;
  name: string;
  title: string;
  email: string;
  phone: string;
  thumbnail: boolean;
  location: Location;
  timeConfig: TimeConfig;
  notification: Notification;
  avatar: string | undefined;
  language: string | undefined;
}
