import {
  GetUnitsByPosResponse,
  GetWeatherResponse,
} from '@wisionmonorepo/api-client-v1/src/responses';
import {
  getWeather,
} from '@wisionmonorepo/api-client-v1/src/requests';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/core/store/types';

type Context = ActionContext<RootState, RootState>;
export default <ActionTree<RootState, RootState>>{
  clearState(context: Context): void {
    context.commit('container/CLEAR_STATE', null, { root: true });
    context.commit('dashboard/CLEAR_STATE', null, { root: true });
    context.commit('device/CLEAR_STATE', null, { root: true });
    context.commit('user/CLEAR_STATE', null, { root: true });
    context.commit('eventManagement/CLEAR_STATE', null, { root: true });
    context.commit('CLEAR_STATE');
  },
  async fetchWeather(
    context: Context, 
    weatherUnit: { unit: GetUnitsByPosResponse, lang: string }
  ): Promise<GetWeatherResponse> {
    const response = await getWeather({
      latitude: weatherUnit.unit.UnitGPSLatitude,
      longitude: weatherUnit.unit.UnitGPSLongitude,
      lang: weatherUnit.lang
    });

    response.id = weatherUnit.unit.UnitID;

    return response || {};
  },
  setDateInterval(context: Context, dateInterval: number): void {
    context.commit('SET_DATE_INTERVAL', dateInterval);
  },
  setTextSearch(context: Context, { id, textSearch }): void {
    context.commit('SET_TEXT_SEARCH', { id, textSearch });
  },
  setFilterSettings(context: Context, { id, filterSettings }): void {
    context.commit('SET_FILTER_SETTINGS', { id, filterSettings });
  },
  removeFilterSettings(context: Context, id: string): void {
    context.commit('REMOVE_FILTER_SETTINGS', id);
  },
};
