export const endpoints = {
  AuthLogin: 'v1/auth/login',
  AuthLogout: 'v1/auth/logout',
  DeleteUserData: 'v1/xrequest/DeleteUserData',
  EditUnitInfo: 'v1/xrequest/EditUnitInfo',
  GetAlarms: 'v1/xrequest/GetAlarms',
  GetAllMarks: 'v1/xrequest/GetAllMarks',
  EditMark: 'v1/xrequest/EditMark',
  DeleteMark: 'v1/xrequest/DeleteMark',
  GetGISData: 'v1/xrequest/GetGISData',
  GetKPIAlarms: 'v1/xrequest/GetKPIAlarms',
  GetKPIBattery: 'v1/xrequest/GetKPIBattery',
  GetKPISwitch: 'v1/xrequest/GetKPISwitch',
  GetRadar: 'v1/xrequest/GetRadar',
  GetRadarDates: 'v1/xrequest/GetRadarDates',
  GetReportParkingUsage: 'v1/xrequest/GetReportParkingUsage',
  GetUnit: 'v1/xrequest/GetUnit',
  GetUnitGroups: 'v1/xrequest/GetUnitGroups',
  GetUnitType: 'v1/xrequest/GetUnitType',
  GetUnitsByPos: 'v1/xrequest/GetUnitsByPos',
  GetUnitsDataCSV: 'v1/xrequest/GetUnitsDataCSV',
  GetUnitsData: 'v1/xrequest/GetUnitsData',
  GetUnitsInfo: 'v1/xrequest/GetUnitsInfo',
  GetUnitsShowValue: 'v1/xrequest/GetUnitsShowValue',
  GetUserData: 'v1/xrequest/GetUserData',
  SetRelay: 'v1/xrequest/SetRelay',
  GetWeather: 'v1/weather/getWeather',
  SetUserData: 'v1/xrequest/SetUserData',
  LoginAccess: 'v1/xrequest/LoginAccess',
  UnitAction: 'v1/xrequest/UnitAction',
  GetChannel: 'v1/xrequest/GetChannel',
  EditChannel: 'v1/xrequest/EditChannel',
};
