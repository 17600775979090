// tslint:disable
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { i18n, initializeLanguage } from '@/core/i18n';
import { router } from '@/core/router';
import store from '@/core/store';
import { Toast } from '@/core/toast';
import axios from 'axios';
import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
import App from './App.vue';
import '@/styles/index.css';
import '@/styles/global.css';
import { ActionTypes as ContainerActions } from '@/core/store/modules/container';
import { ActionTypes as DashboardActions } from '@/core/store/modules/dashboard';
import { ActionTypes as UserActions } from '@/core/store/modules/user';
import { ActionTypes as EventActions } from '@/core/store/modules/event-management';
import { ActionTypes as DeviceActions } from '@/core/store/modules/device';

// Enable Sentry monitoring if not in local mode
if (process.env.VUE_APP_WISION_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.VUE_APP_WISION_RELEASE_ID,
  });
}

Vue.config.productionTip = false;
Vue.use(ToggleButton);

const vue: Vue = new Vue({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  router,
  i18n,
  render: (h) => h(App),
  store,
});

vue.$mount('#app');

(async () => {
  // Make sure language is loaded before proceeding with loading data
  await initializeLanguage(store);

  if (store.getters['user/access']) {
    await store.dispatch(`device/${DeviceActions.FETCH_CURRENT_DEVICES}`, true);
    await store.dispatch(`container/${ContainerActions.FETCH}`);
    await store.dispatch(`dashboard/${DashboardActions.FETCH}`);
    await store.dispatch(`eventManagement/${EventActions.FETCH_ALL}`);

    if (store.getters['device/refreshActive'])
      await store.dispatch(`device/${DeviceActions.START_REFRESH_TIMER}`);
  }
})();

const NOT_AUTHORIZED = 401;
const LOGIN_FAILURE = 417;
let isSessionExpired;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.VUE_APP_WISION_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    isSessionExpired = false;
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === NOT_AUTHORIZED) {
        store.commit('device/CLEAR_STATE', null, { root: true });
        store.commit('CLEAR_STATE', null, { root: true });
        await store.dispatch(`user/${UserActions.CLEAR_STATE}`);
        if (!isSessionExpired) {
          await void router.push({ name: 'login' });
          isSessionExpired = true;
          Toast.createToast(i18n.t('toast.sessionexpired') as string, 'danger');
        }
      } else if (error.response.status === LOGIN_FAILURE) {
        Toast.createToast(i18n.t('toast.invalidcredentials') as string, 'danger');
      }
    } else {
      Toast.createToast(error.message, 'danger');
    }

    return Promise.reject(error);
  }
);
