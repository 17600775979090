// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modify custom scrollbar default styles */
.ps__rail-y {
    width: 10px !important;
}
.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y {
    width: 
    6px !important
}
.ps .ps__rail-x.ps--clicking, .ps .ps__rail-x:focus, .ps .ps__rail-x:hover, .ps .ps__rail-y.ps--clicking, .ps .ps__rail-y:focus, .ps .ps__rail-y:hover {
    background-color: #434443 !important;
}
/* Page backgroud */
.bg-dotted {
    --tw-gradient-to: transparent;
    background-image: radial-gradient(var(--tw-gradient-from) 1px, var(--tw-gradient-to) 0);
    background-size: 12px 12px;
    background-position: -5px -5px;
}

/* Date range picker */
.daterangepicker {
    left: 50% !important;
    top: 20% !important;
    background-color: #434443 !important;
    border-color: rgb(82 82 82 / 1) !important;
}
.vue-daterange-picker {
    position: absolute !important;
    top: 20%;
    left: 40%;
}
.vue-daterange-picker .form-control:first-child{
    visibility: hidden;
}
.daterangepicker .calendars {
    color: #000000;
}
.daterangepicker.openscenter:after, .daterangepicker.openscenter:before {
    display: none;
}
.blur-body {
    filter: blur(8px);
}

/* Dark mode */
.daterangepicker .calendar-table {
    background-color: #434443 !important;
    border: none !important;
}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
    background-color: #434443 !important;
    border-radius: 0px !important;
    border: none !important;
    color: #ffffff;
}
.drp-selected{
    color: #ffffff !important;
}
.daterangepicker td.in-range {
    background-color: #696868 !important;
    color: #ffffff !important;
}
.daterangepicker td.end-date {
    border-radius: 0 4px 4px 0 !important;
}
.daterangepicker td.start-date {
    border-radius: 4px 0 0 4px !important;
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #434443 !important;
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
    border: solid #ffffff !important;
    border-width: 0 2px 2px 0 !important
}
.monthselect, .yearselect {
    background-color: #434443 !important;
}
.daterangepicker .drp-buttons {
    border-color: rgb(162, 161, 161) !important;
}
.daterangepicker .drp-buttons .btn {
    border-color: rgb(162, 161, 161) !important;
    border-radius: 0.5rem !important;
    padding: 8px !important;
    background-color: transparent !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #a3a1a1 !important;
    color: #ffffff !important;
}

@media (min-width: 564px) {
    .daterangepicker.ltr .drp-calendar.left .calendar-table {
        border: none !important;
    }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
