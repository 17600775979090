module.exports = {
  debug: false, //Enable to display console logs for debugging
  alarmTimeFormat: 'L LT',
  cookieAgeDays: 28,
  cookieKey: '{k8o<;$&1"+PA7q/x/xzddO+{QI_1',
  dateFormat: 'YYYY-MM-DD',
  fileCert: 'cert/xtool-local.crt',
  fileKey: 'cert/xtool-local.key',
  languages: 'sv,en,de,da',
  logFormat: ':date[clf] :remote-addr :username :method :url :status :response-time',
  googleMapsApiKey: 'AIzaSyBv2WZyARcGV0ct-14PIngxB9VBobEVbaQ',
  listenHttp: true,
  listenHttps: false,
  portHttp: 3000,
  portHttps: 443,
  timeFormat: 'HH:mm',
  unitsPerPage: 20,
  verifySSL: false,
  logoPath: '/assets/logo/wideco-logo.svg',
  contactUrl: 'https://wideco.se/kontakt/wideco-sweden-ab/',
  logoPathPNG: '/assets/logo/wideco-logo.png',
  website: 'www.wideco.se',
  copyrightText: 'Copyright Wideco Sweden AB 2021 @',
};
