import Vue from 'vue';
import {
  GetWeatherResponse,
} from '@wisionmonorepo/api-client-v1/src/responses';
import { getDefaultState } from '@/core/store';
import { RootState } from '@/core/store/types';
import { MutationTree } from 'vuex';

export default <MutationTree<RootState>>{
  CLEAR_STATE(state: RootState): void {
    Object.assign(state, getDefaultState());
  },
  SET_DATE_INTERVAL(state: RootState, dateInterval: number): void {
    state.persistent.dateInterval = dateInterval;
  },
  SET_WEATHER(state: RootState, weather: GetWeatherResponse): void {
    state.persistent.weather[weather.id] = weather;
  },
  SET_TEXT_SEARCH(state: RootState,{ id, textSearch }): void {
    Vue.set(state.transient.textSearch, id, textSearch);
  },
  SET_FILTER_SETTINGS(state: RootState, { id, filterSettings }): void {
    Vue.set(state.persistent.filterSettings, id, filterSettings);
  },
  REMOVE_FILTER_SETTINGS(state: RootState, id: string): void {
    Vue.delete(state.persistent.filterSettings, id);
  },
};
