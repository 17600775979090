/**
 * @overview Vuex module for user settings
 */
import Vue from 'vue';
import {
  GetUnitsByPosResponse,
  GetUnitsShowValueResponse,
  GetUnitTypeResponse
} from '@wisionmonorepo/api-client-v1/src/responses';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/store/types';
import store from '../index';
import { debug } from '@/../../../../config.js';
import { GetUnitsInfoResponse } from '@wisionmonorepo/api-client-v1/src/responses';
import {
  getUnitsInfo,
  getUnitsShowValue,
  getUnitType,
} from '@wisionmonorepo/api-client-v1/src/requests';
import {
  fetchDevices,
  fetchDeviceDetails,
  fetchDeviceOptions
} from '@wisionmonorepo/api-client-v2/src/requests';
import { getUnitsByPos } from '@wisionmonorepo/api-client-v1/src/requests';
import 
{ DeviceBase, DeviceDetails, DeviceOptions }
  from '@wisionmonorepo/wision-core-service/src/core/model/device';

export interface DeviceState {
  deviceOptions:  { [unitId: string]: GetUnitsShowValueResponse };
  devices: Array<GetUnitsByPosResponse>;
  deviceTypes?: GetUnitTypeResponse[];
  deviceDetails: { [unitId: string]: GetUnitsInfoResponse };
  selectedUnitGroups: string[];
  chartOptions: GetUnitsShowValueResponse[];
  refreshActive: boolean;
  refreshTimer?: NodeJS.Timer;
  refreshKey: number;
}
 
type Context = ActionContext<DeviceState, RootState>;
 
export enum MutationTypes {
    CLEAR_STATE = 'CLEAR_STATE',
    SET_DEVICE_OPTIONS = 'SET_DEVICE_OPTIONS',
    SET_CHART_OPTIONS = 'SET_CHART_OPTIONS',
    SET_DEVICE_TYPES = 'SET_DEVICE_TYPES',
    SET_DEVICE_DETAILS = 'SET_DEVICE_DETAILS',
    SET_DEVICES = 'SET_DEVICES',
    SET_SELECTED_UNIT_GROUPS = 'SET_SELECTED_UNIT_GROUPS',
    TOGGLE_REFRESH = 'TOGGLE_REFRESH',
    SET_REFRESH_TIMER = 'SET_REFRESH_TIMER',
    UPDATE_REFRESH_KEY = 'UPDATE_REFRESH_KEY',
 }
 
export enum ActionTypes {
  FETCH_DEVICE_OPTIONS = 'FETCH_DEVICE_OPTIONS',
  CACHE_DEVICE_DETAILS = 'CACHE_DEVICE_DETAILS',
  FETCH_DEVICE_TYPES = 'FETCH_DEVICE_TYPES',
  FETCH_DEVICE_DETAILS = 'FETCH_DEVICE_DETAILS',
  FETCH_CURRENT_DEVICES = 'FETCH_CURRENT_DEVICES',
  FETCH_CHART_OPTIONS = 'FETCH_CHART_OPTIONS',
  FETCH_CURRENT_DEVICES_V2 = 'FETCH_CURRENT_DEVICES_V2',
  FETCH_DEVICE_DETAILS_V2 = 'FETCH_DEVICE_DETAILS_V2',
  FETCH_DEVICE_OPTIONS_V2 = 'FETCH_DEVICE_OPTIONS_V2',
  TOGGLE_REFRESH = 'TOGGLE_REFRESH',
  START_REFRESH_TIMER = 'START_REFRESH_TIMER',
  STOP_REFRESH_TIMER = 'STOP_REFRESH_TIMER',
}
 
export const getDefaultState = () => ({
  deviceOptions: {},
  deviceTypes: undefined,
  deviceDetails: {},
  chartOptions: [],
  devices: [],
  selectedUnitGroups: [],
  refreshActive: false,
  refreshTimer: undefined,
  refreshKey: 0,
});
 
const state = getDefaultState();

const REFRESH_INTERVAL = 1000 * 60 * 5; // Once every five minute

const mutations = <MutationTree<DeviceState>>{
  [MutationTypes.CLEAR_STATE] (state: DeviceState) {
    Object.assign(state, getDefaultState());
  },
  [MutationTypes.SET_DEVICE_OPTIONS] (state: DeviceState, options: Array<GetUnitsShowValueResponse>) {
    options.forEach((unitOption: GetUnitsShowValueResponse) =>
      Vue.set(state.deviceOptions, unitOption.UnitID, options[0]));
  },
  [MutationTypes.SET_DEVICE_DETAILS] (state: DeviceState, device: GetUnitsInfoResponse) {
    Vue.set(state.deviceDetails, device.UnitID, device);
  },
  [MutationTypes.SET_DEVICE_TYPES] (state: DeviceState, types: GetUnitTypeResponse[]) {
    state.deviceTypes = types;
  },
  [MutationTypes.SET_DEVICES] (state: DeviceState, devices: GetUnitsByPosResponse[]) {
    state.devices = devices;
  },
  [MutationTypes.SET_SELECTED_UNIT_GROUPS] (state: DeviceState, unitGroups: string[]): void {
    state.selectedUnitGroups = unitGroups;
  },
  [MutationTypes.SET_CHART_OPTIONS] (state: DeviceState, chartOptions: GetUnitsShowValueResponse[]): void {
    state.chartOptions = chartOptions;
  },
  [MutationTypes.TOGGLE_REFRESH] (state: DeviceState): void {
    state.refreshActive = !state.refreshActive;
  },
  [MutationTypes.SET_REFRESH_TIMER] (state: DeviceState, timer?: NodeJS.Timer): void {
    state.refreshTimer = timer;
  },
  [MutationTypes.UPDATE_REFRESH_KEY] (state: DeviceState): void {
    state.refreshKey = state.refreshKey + 1;
  }
};

const actions = <ActionTree<DeviceState, RootState>> {
  // eslint-disable-next-line max-len
  async [ActionTypes.FETCH_DEVICE_OPTIONS] 
  (context: Context, units: GetUnitsByPosResponse[]): Promise<GetUnitsShowValueResponse[]> {
    const customerIds: number[] = context.getters['user/selectedCustomerIds'];
    const unitIds: number[] = units.map((unit: GetUnitsByPosResponse) => unit.UnitID);

    const optionsResponse: GetUnitsShowValueResponse[] = await getUnitsShowValue({ unitIds, customerIds });

    context.commit('SET_DEVICE_OPTIONS', optionsResponse);

    return optionsResponse;
  },
  async [ActionTypes.CACHE_DEVICE_DETAILS] (context: Context, unitIds: Array<number>): Promise<void> {
    const missingIds = unitIds.filter(unitId => !context.state.deviceDetails[unitId]);

    if (!missingIds.length) return;

    if (debug) console.info('Caching unit details:', missingIds);

    const response = await getUnitsInfo({ unitIds: missingIds });

    response.forEach((unit: GetUnitsInfoResponse) => {
      context.commit('SET_DEVICE_DETAILS', unit);
    });
  },
  async [ActionTypes.FETCH_DEVICE_DETAILS] (context: Context, unitId: number): Promise<GetUnitsInfoResponse> {
    if (!context.state.deviceDetails[unitId]) {
      const response = await getUnitsInfo({ unitIds: [ unitId ] });
      context.commit('SET_DEVICE_DETAILS', response[0]);
    }

    return context.state.deviceDetails[unitId] || {};
  },
  async [ActionTypes.FETCH_CURRENT_DEVICES] (context: Context, force = false): Promise<void> {
    if (context.state.devices.length && !force) {
      return;
    }

    const response = await getUnitsByPos({
      customerIds: store.getters['user/selectedCustomerIds'],
    });

    if (response) {
      context.commit('SET_DEVICES', response);
    }
  },
  async [ActionTypes.FETCH_DEVICE_TYPES] (context: Context): Promise<GetUnitTypeResponse[]> {
    if (!context.state.deviceTypes) {
      const customerIds: number[] = context.getters['user/selectedCustomerIds'];
      const response: GetUnitTypeResponse[] = await getUnitType({ customerIds });

      if (response.length > 0) {
        context.commit('SET_DEVICE_TYPES', response);
      }
    }

    return context.state.deviceTypes || [];
  },
  async [ActionTypes.FETCH_CHART_OPTIONS] (context: Context, unitId: number): Promise<GetUnitsShowValueResponse> {
    const customerIds: number[] = context.getters['user/selectedCustomerIds'];
    if (!context.state.deviceOptions[unitId]) {
      const optionsResponse: GetUnitsShowValueResponse[] = await getUnitsShowValue({
        customerIds,
        unitIds: [unitId],
      });

      context.commit('SET_DEVICE_OPTIONS', optionsResponse);
    }

    return context.state.deviceOptions[unitId];
  },

  //V2
  async [ActionTypes.FETCH_CURRENT_DEVICES_V2] (
    context: Context,
    customers: Array<number> = []
  ): Promise<Array<DeviceBase>> {
    return await fetchDevices(customers);
  },
  async [ActionTypes.FETCH_DEVICE_DETAILS_V2] (context: Context, unitId: number): Promise<DeviceDetails> {
    return await fetchDeviceDetails(unitId);
  },
  async [ActionTypes.FETCH_DEVICE_OPTIONS_V2] (context: Context, unitId: number): Promise<DeviceOptions> {
    return await fetchDeviceOptions(unitId);
  },
  async [ActionTypes.START_REFRESH_TIMER] (context: Context) {
    context.commit(MutationTypes.SET_REFRESH_TIMER, setInterval(() => {
      context.dispatch(ActionTypes.FETCH_CURRENT_DEVICES, true);
      context.commit(MutationTypes.UPDATE_REFRESH_KEY);
      console.info('Refresh...');
    }, REFRESH_INTERVAL));
    console.info('Timer started');
  },
  async [ActionTypes.STOP_REFRESH_TIMER] (context: Context) {
    clearInterval(Number(context.state.refreshTimer));
    context.commit(MutationTypes.SET_REFRESH_TIMER, undefined);
    console.info('Timer stopped');
  },
  async [ActionTypes.TOGGLE_REFRESH] (context: Context) {
    context.commit(MutationTypes.TOGGLE_REFRESH);

    if (context.state.refreshActive) {
      context.dispatch(ActionTypes.START_REFRESH_TIMER);
    } else {
      context.dispatch(ActionTypes.STOP_REFRESH_TIMER);
    }
  },
};

const getters = <GetterTree<DeviceState, RootState>> {
  devices(state: DeviceState){
    return state.devices;
  },
  deviceOptions(state: DeviceState){
    return state.deviceOptions;
  },
  deviceTypes(state: DeviceState){
    return state.deviceTypes;
  },
  deviceDetails(state: DeviceState){
    return state.deviceDetails;
  },
  chartOptions(state: DeviceState){
    return state.chartOptions;
  },
  selectedUnitGroups(state: DeviceState): string[] {
    return state.selectedUnitGroups;
  },
  refreshActive(state: DeviceState): boolean {
    return state.refreshActive;
  },
  refreshKey(state: DeviceState): number {
    return state.refreshKey;
  }
};
 
export default <Module<DeviceState, RootState>>{
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
