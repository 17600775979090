import { alarmStatusFilter, parkingUnitFilter } from '@/core/filter';
import { appendLinkGroup, DEFAULT_LINK_GROUP_ID, DEFAULT_LINK_GROUPS, LinkGroups } from '@/core/models/linkGroup';
import { Bindings, BindingValue, ValueContext, BindingKind } from '@/core/models/binding';
import { Widget } from '@/core/models/widget';
import clone from 'just-clone';
import { nanoid } from 'nanoid';

export const GRID_COL_BREAKPOINTS = { md: 12, sm: 6, xs: 2 };

export type Breakpoint = 'md' | 'sm' | 'xs'

export type StaticDashboard = 'alarms' | 'map' | 'overview' | 'parking' | 'telemetry' | 'units'

const modelVersion = '1';

export interface WidgetSettings { [key: string]: unknown }

export interface DashboardModel {
  name: string;
  version: string;
  static?: StaticDashboard,
  unitGroups: string[];
  linkGroups: LinkGroups;
  bindings: Bindings;
  layouts: ResponsiveLayoutConfiguration;
  widgets: { [widgetId: string]: WidgetConfiguration };
}

// vue-grid-layout configuration
export interface LayoutConfiguration {
  x?: number;
  y?: number;
  w: number;
  h: number;
  mH?: number;
  mW?: number;
  i: string; // Corresponds to widgetId
}

export interface WidgetConfiguration {
  component: string;
  props?: Record<string, unknown>;
  settings?: WidgetSettings;
  name?: string;
  group?: string; // Id of a link group associated with the widget
}

// vue-grid-layout configuration
type ResponsiveLayoutConfiguration = {
  [id in Breakpoint]: LayoutConfiguration[];
};

export const emptyDashboard: DashboardModel = {
  name: 'Default',
  version: modelVersion,
  unitGroups: [],
  linkGroups: DEFAULT_LINK_GROUPS,
  bindings: {},
  layouts: {
    'md': [],
    'sm': [],
    'xs': [],
  },
  widgets: {},
};

export const staticDashboards: Record<string, DashboardModel> = {
  'overview': {
    name: 'nav.overview',
    static: 'overview',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md: [
        { x: 0, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'status' },
        { x: 2, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'temp' },
        { x: 4, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'battery' },
        { x: 0, y: 5, w: 2, h: 6, mW: 2, mH: 3, i: 'alarm' },
        { x: 2, y: 5, w: 4, h: 6, mW: 2, mH: 3, i: 'weather' },
        { x: 6, y: 11, w: 6, h: 11, mW: 2, mH: 3, i: 'unitmap' },
      ],
      sm: [
        { x: 0, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'status' },
        { x: 2, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'temp' },
        { x: 4, y: 0, w: 2, h: 5, mW: 2, mH: 3, i: 'battery' },
        { x: 0, y: 5, w: 2, h: 6, mW: 2, mH: 3, i: 'alarm' },
        { x: 2, y: 5, w: 4, h: 6, mW: 2, mH: 3, i: 'weather' },
        { x: 0, y: 11, w: 6, h: 11, mW: 2, mH: 3, i: 'unitmap' },
      ],
      xs: [
        { x: 0, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'status' },
        { x: 1, y: 0, w: 1, h: 3, mW: 1, mH: 3, i: 'alarm' },
        { x: 0, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'battery' },
        { x: 1, y: 6, w: 1, h: 3, mW: 1, mH: 3, i: 'temp' },
        { x: 0, y: 12, w: 1, h: 3, mW: 1, mH: 3, i: 'weather' },
        { x: 1, y: 12, w: 1, h: 3, mW: 1, mH: 3, i: 'unitmap' },
      ]
    },
    widgets: {
      status: { component: 'StatusWidget', name: 'widgets.status.name', group: 'default' },
      alarm: { component: 'AlarmWidget', name: 'widgets.alarms.name', group: 'default' },
      battery: { component: 'BatteryWidget', name: 'widgets.battery.name', group: 'default' },
      temp: { component: 'TempWidget', name: 'widgets.temp.name', group: 'default' },
      weather: { component: 'WeatherWidget', name: 'widgets.weather.name', group: 'default' },
      unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
    },
  },
  'units': {
    name: 'nav.units',
    static: 'units',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md: [
        { x: 0, y: 0, w: 7, h: 12, mW: 2, mH: 3, i: 'unitlist' },
        { x: 7, y: 0, w: 5, h: 12, mW: 2, mH: 3, i: 'unitmap' },
      ],
      sm: [
        { x: 0, y: 0, w: 3, h: 8, mW: 2, mH: 3, i: 'unitlist' },
        { x: 3, y: 0, w: 3, h: 8, mW: 2, mH: 3, i: 'unitmap' },
      ],
      xs: [
        { x: 0, y: 0, w: 2, h: 5, mW: 1, mH: 3, i: 'unitlist' },
        { x: 0, y: 5, w: 2, h: 4, mW: 1, mH: 3, i: 'unitmap' },
      ]
    },
    widgets: {
      unitlist: { component: 'UnitListWidget', group: 'default', props: { multiSelect: true } },
      unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
    },
  },
  'alarms': {
    name: 'nav.alarmlist',
    static: 'alarms',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md: [
        { x: 0, y: 0, w: 8, h: 12, mW: 2, mH: 3, i: 'unitalarmlist' },
        { x: 8, y: 0, w: 4, h: 12, mW: 2, mH: 3, i: 'alarmmap' },
      ],
      sm: [
        { x: 0, y: 0, w: 3, h: 8, mW: 2, mH: 3, i: 'unitalarmlist' },
        { x: 3, y: 3, w: 3, h: 8, mW: 2, mH: 3, i: 'alarmmap' },
      ],
      xs: [
        { x: 0, y: 0, w: 2, h: 5, mW: 1, mH: 3, i: 'unitalarmlist' },
        { x: 0, y: 5, w: 2, h: 4, mW: 1, mH: 3, i: 'alarmmap' },
      ]
    },
    widgets: {
      unitalarmlist: { component: 'UnitAlarmWidget', group: 'default' },
      alarmmap: {
        component: 'MapWidget',
        group: 'default',
        props: {
          type: 'alarms',
          filter: alarmStatusFilter,
        },
        name: 'widgets.alarmmap.name'
      },
    },
  },
  'map': {
    name: 'nav.map',
    static: 'map',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md:[
        { x: 6, y: 0, w: 6, h: 12, mW: 2, mH: 3, i: 'chart' },
        { x: 0, y: 0, w: 6, h: 12, mW: 2, mH: 3, i: 'unitmap' },
      ],
      sm:[
        { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'unitmap' },
        { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'chart' },
      ],
      xs:[
        { x: 0, y: 0, w: 2, h: 8, mW: 1, mH: 3, i: 'unitmap' },
      ]
    },
    widgets: {
      chart: { component: 'ChartWidget', group: 'default' },
      unitmap: { component: 'MapWidget', name: 'widgets.unitmap.name', group: 'default' },
    },
  },
  'telemetry': {
    name: 'nav.data',
    static: 'telemetry',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md:[
        { x: 4, y: 0, w: 8, h: 12, mW: 2, mH: 3, i: 'chart' },
        { x: 0, y: 0, w: 4, h: 12, mW: 2, mH: 3, i: 'unitlist' },
      ],
      sm:[
        { x: 0, y: 0, w: 6, h: 8, mW: 2, mH: 3, i: 'chart' },
        { x: 0, y: 8, w: 6, h: 8, mW: 2, mH: 3, i: 'unitlist' },
      ],
      xs:[
        { x: 0, y: 4, w: 2, h: 5, mW: 1, mH: 3, i: 'chart' },
        { x: 0, y: 0, w: 2, h: 4, mW: 1, mH: 3, i: 'unitlist' },
      ]
    },
    widgets: {
      chart: { component: 'ChartWidget', group: 'default' },
      unitlist: { component: 'UnitListWidget', group: 'default' },
    }
  },
  'parking': {
    name: 'nav.parking',
    static: 'parking',
    version: modelVersion,
    unitGroups: [],
    linkGroups: DEFAULT_LINK_GROUPS,
    bindings: {},
    layouts: {
      md:[
        { x: 0, y: 0, w: 12, h: 5, mW: 2, mH: 3, i: 'stats' },
        { x: 0, y: 5, w: 6, h: 8, mW: 2, mH: 3, i: 'parkinglist' },
        { x: 6, y: 5, w: 6, h: 8, mW: 2, mH: 3, i: 'parkingmap' },
      ],
      sm:[
        { x: 0, y: 0, w: 6, h: 5, mW: 2, mH: 3, i: 'stats' },
        { x: 0, y: 5, w: 6, h: 4, mW: 2, mH: 3, i: 'parkinglist' },
        { x: 0, y: 9, w: 6, h: 4, mW: 2, mH: 3, i: 'parkingmap' },
      ],
      xs:[
        { x: 0, y: 0, w: 2, h: 6, mW: 1, mH: 3, i: 'stats' },
        { x: 0, y: 6, w: 2, h: 3, mW: 1, mH: 3, i: 'parkinglist' },
        { x: 0, y: 9, w: 2, h: 3, mW: 1, mH: 3, i: 'parkingmap' },
      ]
    },
    widgets: {
      stats: { component: 'ParkingStatisticsWidget', group: 'default' },
      parkinglist: { component: 'ParkingListWidget', group: 'default' },
      parkingmap: {
        component: 'MapWidget',
        group: 'default',
        props: {
          filter: parkingUnitFilter,
        },
        name: 'widgets.parkingmap.name'
      },
    },
  }
};

/**
 *  Delete all references to widget in dashboard bindings, and all it's manual binding
 */
export const deleteWidgetReferences = (dashboard: Readonly<DashboardModel>, widgetId: string): DashboardModel => {
  const bindings = Object.entries(dashboard.bindings)
    .reduce((acc: Bindings, [bindingKey, linkBindings]: [string, ValueContext]) => {
      const newLinkBindings = Object.fromEntries(
        Object.entries(linkBindings).filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([key, value]) => value.updatedBy !== widgetId && !(bindingKey === widgetId && value.updatedBy === 'manual'))
      );

      return {
        ...acc,
        [bindingKey]: newLinkBindings,
      };
    }, {});

  return {
    ...dashboard,
    bindings: bindings,
  };
};

/**
 *  Delete widget from dashboard
 */
export const deleteWidget = (dashboard: Readonly<DashboardModel>, widgetId: string): DashboardModel => {
  const layouts = clone(dashboard.layouts);
  const widgets = clone(dashboard.widgets);

  for (const breakPointName of Object.keys(GRID_COL_BREAKPOINTS)) {
    const indexToDelete = layouts[breakPointName].map(item => item.i).indexOf(widgetId);
    if (indexToDelete >= 0) {
      layouts[breakPointName].splice(indexToDelete, 1);
    }
  }

  if (widgets[widgetId]) {
    delete widgets[widgetId];
  }

  const bindings = deleteWidgetReferences(dashboard, widgetId).bindings;

  return {
    ...dashboard,
    widgets,
    layouts,
    bindings,
  };
};

/**
 *  Add widget to dashboard
 */
export const addWidget = (dashboard: Readonly<DashboardModel>, widget: Widget): DashboardModel => {
  const layouts = clone(dashboard.layouts);

  // Making widget id unique to have multiple instance
  const breakpoint = Object.keys(GRID_COL_BREAKPOINTS)[0];
  let id = widget.layouts[breakpoint].i + nanoid(10);

  while (dashboard.widgets[id]) {
    id = widget.layouts[breakpoint].i + nanoid(10);
  }

  for (const breakPointName of Object.keys(GRID_COL_BREAKPOINTS)) {
    // Check and add breakpoints to layouts
    if (!layouts[breakPointName]) {
      layouts[breakPointName] = [];
    }

    const breakpointLayout: LayoutConfiguration = {
      x: (dashboard.layouts[breakPointName].length * widget.layouts[breakPointName].w)
        % (GRID_COL_BREAKPOINTS[breakPointName]),
      y: dashboard.layouts[breakPointName].reduce((height, widget) => (height + widget.h), 0)
        + GRID_COL_BREAKPOINTS[breakPointName],
      w: widget.layouts[breakPointName].w,
      h: widget.layouts[breakPointName].h,
      mH: widget.layouts[breakPointName].mH,
      mW: widget.layouts[breakPointName].mW,
      i: id
    };

    layouts[breakPointName] = layouts[breakPointName].concat([ breakpointLayout ]);
  }

  const widgetConfiguration: WidgetConfiguration = {
    name: widget.name,
    component: widget.component,
    group: DEFAULT_LINK_GROUP_ID,
    props: widget.props
  };

  return {
    ...dashboard,
    widgets: {
      ...dashboard.widgets,
      [id]: widgetConfiguration,
    },
    layouts,
  };
};

/**
 *  Add link group to dashboard
 */
export const addLinkGroup = (dashboard: Readonly<DashboardModel>, name: string, color: string): DashboardModel => {
  const linkGroups = appendLinkGroup(dashboard.linkGroups, name, color);

  return {
    ...dashboard,
    linkGroups,
  };
};

/**
 *  Remove link group from dashboard
 */
export const deleteLinkGroup = (dashboard: Readonly<DashboardModel>, groupId: string): DashboardModel => {
  // Use destructuring syntax to immutable remove a link group
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { [groupId]: value, ...remainingLinkGroups } = dashboard.linkGroups;

  const widgets = clone(dashboard.widgets);

  // Remove link in all widgets
  Object.entries(widgets).forEach(([widgetId, widget]: [string, WidgetConfiguration]) => {
    if (widget.group === groupId) {
      widgets[widgetId] = { ...widget, group: undefined } as WidgetConfiguration;
    }
  });

  return {
    ...dashboard,
    linkGroups: remainingLinkGroups,
    widgets,
  };
};

/**
 *  Link widget to link group
 */
export const linkWidgetGroup = (
  dashboard: Readonly<DashboardModel>,
  widgetId: string,
  linkGroup: string
): DashboardModel => {
  if (!dashboard.linkGroups[linkGroup]) throw new Error('Link group does not exist');

  const widget = dashboard.widgets[widgetId];

  const bindings = deleteWidgetReferences(dashboard, widgetId).bindings;

  return {
    ...dashboard,
    bindings: bindings,
    widgets: {
      ...dashboard.widgets,
      [widgetId]: {
        ...widget,
        group: linkGroup,
      }
    }
  };
};

/**
 *  Unlink widget
 */
export const unlinkWidgetGroup = (dashboard: Readonly<DashboardModel>, widgetId: string): DashboardModel => {
  const widget = dashboard.widgets[widgetId];

  const bindings = deleteWidgetReferences(dashboard, widgetId).bindings;

  return {
    ...dashboard,
    bindings: bindings,
    widgets: {
      ...dashboard.widgets,
      [widgetId]: {
        ...widget,
        group: undefined,
      }
    }
  };
};

/**
 * Bind value
 */
export const bindValue = (
  dashboard: Readonly<DashboardModel>,
  widgetId: string,
  value: BindingValue,
  manual?: boolean
): DashboardModel => {
  const bindingKey = manual ? widgetId : dashboard.widgets[widgetId]?.group || widgetId;

  const context: ValueContext = dashboard.bindings[bindingKey];

  const updatedLinkBindings = {
    ...context,
    [value.kind]: value,
  };

  return {
    ...dashboard,
    bindings: { ...dashboard.bindings, [bindingKey]: updatedLinkBindings },
  };
};

/**
 * Unbind value
 */
export const unbindValue = (
  dashboard: Readonly<DashboardModel>,
  widgetId: string,
  kind: BindingKind,
): DashboardModel => {
  const bindingKey = dashboard.widgets[widgetId]?.group || widgetId;

  const context: ValueContext = dashboard.bindings[bindingKey];

  // Remove the requested binding using destructuring syntax
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { [kind]: discarded, ...remaining } = context;

  return {
    ...dashboard,
    bindings: { ...dashboard.bindings, [bindingKey]: remaining },
  };
};
