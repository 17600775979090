import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { RootState } from '@/core/store/types';
import dashboard from './modules/dashboard';
import container from './modules/container';
import user from './modules/user';
import device, { getDefaultState as getDeviceDefaultState }  from '@/core/store/modules/device';
import eventManagement from './modules/event-management';

Vue.use(Vuex);

type DefaultState = () => RootState;

export const getDefaultState: DefaultState = () => ({
  persistent: {
    deviceState: getDeviceDefaultState(),
    dateInterval: 4,
    weather: {},
    widgetsHidden: { Data: ['UnitWeather'] },
    filterSettings: {},
  },
  transient: {
    textSearch: {},
  },
});

const index: StoreOptions<RootState> = {
  actions,
  getters,
  mutations,
  plugins: [
    createPersistedState({
      paths: [
        'persistent',
        'user',
        'device'
      ],
    })],
  state: getDefaultState(),
  modules: {
    user,
    dashboard,
    container,
    eventManagement,
    device
  }
};

export default new Vuex.Store<RootState>(index);
