/**
 * @overview Notification data model
 */

import { AlarmEvent } from '@/core/models/event';
import { nanoid } from 'nanoid';
import { DbEntity, DbRecord, EntityKind } from '@/core/models/dbEntity';
import { i18n } from '@/core/i18n';
import clone from 'just-clone';
import { criticalAlarmTypes } from '@wisionmonorepo/core-device-support/src/enums';

export enum NotificationPriority {
  Low = 'low',
  Normal = 'normal',
  High = 'high',
  Critical = 'critical',
}

export enum NotificationType {
  Alarm = 'alarm',
}

export interface Notification extends DbEntity {
  eventId: string;
  type: NotificationType;
  message: string;
  seen?: Date;
  priority: NotificationPriority;
}

const modelVersion = '1';

/**
 * Create alarm notification model from alarm API response
 */
export const createAlarmNotification = (event: AlarmEvent): Notification => {
  const id = nanoid();
  const created = new Date();

  const alarmName = i18n.t(`alarmtypes.${event.alarm.AlarmType}`);
  const message = `${alarmName}: ${event.alarm.AlarmEventText}`;

  const priority = criticalAlarmTypes.includes(event.alarm.AlarmType) ?
    NotificationPriority.Critical :
    NotificationPriority.Normal;

  return {
    id,
    created,
    version: modelVersion,
    kind: EntityKind.Notification,
    eventId: event.id,
    type: NotificationType.Alarm,
    message,
    priority,
    seen: undefined,
  };
};

/**
 * Mark notification as seen
 */
export const markNotificationAsSeen = (notification: Notification): Notification => {
  if (!notification) throw new Error('Invalid notification parameter');

  const clonedNotification = clone(notification);

  clonedNotification.seen = new Date();

  return clonedNotification;
};

/**
 * Validate notification model
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateNotification = (notification): notification is Notification => {
  return !!notification &&
    notification.kind === EntityKind.Notification &&
    !!notification.created &&
    !!notification.id && typeof notification.id === 'string' &&
    !!notification.type && typeof notification.type === 'string' &&
    !!notification.message && typeof notification.message === 'string' &&
    !!notification.eventId && typeof notification.eventId === 'string' &&
    !!notification.priority && typeof notification.priority == 'string';
};

/**
 * Validate notification database record
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateNotificationRecord = (data): data is DbRecord<Notification> => {
  if (!data) return false;

  if (typeof data !== 'object') return false;

  const notification = Object.values(data)?.[0];

  return validateNotification(notification);
};
