/**
 * @overview API endpoints
 */

export const endpoints = {
  AuthLogin: '/api/v2/auth/login',
  AuthLogout: '/api/v2/auth/logout',
  AuthSessions: '/api/v2/auth/sessions',
  AuthLogoutSession: '/api/v2/auth/session/logout',
  UserProfile: '/api/v3/user/profile',
  UserProfileAvatar: '/api/v3/user/profile/avatar',
  UserProfilePassword: '/api/v3/user/profile/password',
  Devices: '/api/v1/device',
  TableReport: '/api/v2/report/table'
};
