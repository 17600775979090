/**
 * Generic responses
 */
export interface ResultResponse {
  Result: string;
}

export type EmptyObjectResponse = Record<string, never>;

export type EmptyStringResponse = '';

export type ListErrorResponse = [ResultResponse] | [EmptyObjectResponse];

/**
 * LoginAccess
 */
export interface LoginAccessResponse {
  AccessLevel: AccessLevel;
  AccessWebService: AccessWebService;
  Customers: Customer[];
  FullName: string;
  Language: number;
  UserName: string;
}
export interface Customer {
  CustomerID: number;
  CustomerName: string;
  CustomerRights: CustomerRights[];
}
export interface CustomerRights {
  U: Access[];
  Widget?: Widget;
}
export interface Access {
  AccessLevel: AccessLevel;
  AccessWebService: AccessWebService;
  FullName: string;
  UserName: string;
}
export enum AccessLevel {
  SuperUser = 0,
  User = 1,
  Reader = 2,
}
export enum Widget {
  TyrensGIS = 1,
  AWG = 2,
  Parking = 3,
}
export type AccessWebService = 0 | 1;

/**
 * GetAlarms
 */
export interface GetAlarmsResponse {
  AlarmEventAckTime: string;
  AlarmEventResetTime: string;
  AlarmEventStatus: number;
  AlarmEventText: string;
  AlarmEventTime: string;
  AlarmID: number;
  AlarmType: number;
  ChannelID: number;
  RowNum: number;
  TotalRows: number;
  UnitID: number;
  Units: Array<{
    UnitType: number;
  }>;
}

/**
 * GetUnitsByPos
 */
export interface GetUnitsByPosResponse {
  AlarmEvents: AlarmEvent[];
  CustomerID: number;
  RowNum: number;
  TotalRows: number;
  UnitDescription: string;
  UnitGPSLatitude: number;
  UnitGPSLongitude: number;
  UnitGroupId: number;
  UnitID: number;
  UnitLastSuccessConnection: string;
  UnitLocation: string;
  UnitName: string;
  UnitSerialNumber: string;
  UnitType: number;
}

export interface AlarmEvent {
  AlarmEventCreationTime?: string;
  AlarmType?: number;
  PrioAlarmType: number;
}

export interface GetUnitResponse  {
   UnitID: number;
   CustomerID: number;
   UnitType: number;
   UnitName: string;
   UnitSerialNumber: string;
   UnitDescription: string;
   UnitLocation: string;
   UnitComment: string;
   UnitSchedule: number;
   UnitContinousReading: number;
   UnitGroupId: number;
   UnitDisabled: number;
   UnitAlarmActive: number;
   UnitAdress: string;
   UnitPort: number;
   UnitActiveFTP: number;
   UnitCurrentAdress: string;
   UnitCurrentPort: number;
   UnitCurrentMask: string;
   UnitCurrentGateway: string;
   UnitReplySocketAdress: string;
   UnitReplySocketPort: number;
   UnitReplySocketAdress2: string;
   UnitReplySocketPort2: number;
   UnitCommunicationAlarmID: number;
   UnitMaskAlarmConnection: number;
   UnitLastConnection: string;
   UnitLastCommunicationErrorStart: string;
   UnitLastSuccessConnection: string;
   UnitCommunicationStarted: string;
   UnitCommunicationSessionStarted: string;
   UnitCommunicationMachine: string;
   UnitCommunicationErrorCounter: number;
   UnitCommunicationErrorCounterStart: string;
   UnitVPNAdress: string;
   UnitVPNUserName: string;
   UnitVPNPassword: string;
   UnitAnalogAlarmRetriggerTime: number;
   UnitShowChannel1: number;
   UnitShowChannel2: number;
   UnitShowChannel3: number;
   UnitShowChannel4: number;
   UnitShowAnalog1: number;
   UnitAnalog1AlarmLow: number;
   UnitAnalog1AlarmHigh: number;
   UnitAnalog1AlarmId: number;
   UnitAnalog1Type: number;
   UnitAnalog1Description: string;
   UnitAnalog1Comment: string;
   UnitAnalog1MeasureUnit: string;
   UnitAnalog1ScaleFactor: number;
   UnitAnalog1Offset: number;
   UnitAnalog1YKXMX1: number;
   UnitAnalog1YKXMY1: number;
   UnitAnalog1YKXMX2: number;
   UnitAnalog1YKXMY2: number;
   UnitShowAnalog2: number;
   UnitAnalog2AlarmLow: number;
   UnitAnalog2AlarmHigh: number;
   UnitAnalog2AlarmId: number;
   UnitAnalog2Type: number;
   UnitAnalog2Description: string;
   UnitAnalog2Comment: string;
   UnitAnalog2MeasureUnit: string;
   UnitAnalog2ScaleFactor: number;
   UnitAnalog2Offset: number;
   UnitAnalog2YKXMX1: number;
   UnitAnalog2YKXMY1: number;
   UnitAnalog2YKXMX2: number;
   UnitAnalog2YKXMY2: number;
   UnitShowAnalog3: number;
   UnitAnalog3AlarmLow: number;
   UnitAnalog3AlarmHigh: number;
   UnitAnalog3AlarmId: number;
   UnitAnalog3Type: number;
   UnitAnalog3Description: string;
   UnitAnalog3Comment: string;
   UnitAnalog3MeasureUnit: string;
   UnitAnalog3ScaleFactor: number;
   UnitAnalog3Offset: number;
   UnitAnalog3YKXMX1: number;
   UnitAnalog3YKXMY1: number;
   UnitAnalog3YKXMX2: number;
   UnitAnalog3YKXMY2: number;
   UnitShowAnalog4: number;
   UnitAnalog4AlarmLow: number;
   UnitAnalog4AlarmHigh: number;
   UnitAnalog4AlarmId: number;
   UnitAnalog4Type: number;
   UnitAnalog4Description: string;
   UnitAnalog4Comment: string;
   UnitAnalog4MeasureUnit: string;
   UnitAnalog4ScaleFactor: number;
   UnitAnalog4Offset: number;
   UnitAnalog4YKXMX1: number;
   UnitAnalog4YKXMY1: number;
   UnitAnalog4YKXMX2: number;
   UnitAnalog4YKXMY2: number;
   UnitShowCustom1: number;
   UnitShowCustomFormula1: string;
   UnitShowCustomDescription1: string;
   UnitShowCustomUnit1: string;
   UnitShowCustom2: number;
   UnitShowCustomFormula2: string;
   UnitShowCustomDescription2: string;
   UnitShowCustomUnit2: string;
   UnitShowCustom3: number;
   UnitShowCustomFormula3: string;
   UnitShowCustomDescription3: string;
   UnitShowCustomUnit3: string;
   UnitShowCustom4: number;
   UnitShowCustomFormula4: string;
   UnitShowCustomDescription4: string;
   UnitShowCustomUnit4: string;
   UnitPT1Type: number;
   UnitPT1AlarmLow: number;
   UnitPT1AlarmHigh: number;
   UnitPT1AlarmId: number;
   UnitShowPT1: number;
   UnitPT2Type: number;
   UnitPT2AlarmLow: number;
   UnitPT2AlarmHigh: number;
   UnitPT2AlarmId: number;
   UnitShowPT2: number;
   UnitPT3Type: number;
   UnitPT3AlarmLow: number;
   UnitPT3AlarmHigh: number;
   UnitPT3AlarmId: number;
   UnitShowPT3: number;
   UnitPT4Type: number;
   UnitPT4AlarmLow: number;
   UnitPT4AlarmHigh: number;
   UnitPT4AlarmId: number;
   UnitShowPT4: number;
   UnitX1LevelSwitch1AlarmOn: number;
   UnitX1LevelSwitch1AlarmId: number;
   UnitX1LevelSwitchDescription1: string;
   UnitX1LevelSwitchShow1: number;
   UnitX1LevelSwitchNormalClosed1: number;
   UnitX1LevelSwitch2AlarmOn: number;
   UnitX1LevelSwitch2AlarmId: number;
   UnitX1LevelSwitchDescription2: string;
   UnitX1LevelSwitchShow2: number;
   UnitX1LevelSwitchNormalClosed2: number;
   UnitX1LevelSwitch3AlarmOn: number;
   UnitX1LevelSwitch3AlarmId: number;
   UnitX1LevelSwitchDescription3: string;
   UnitX1LevelSwitchShow3: number;
   UnitX1LevelSwitchNormalClosed3: number;
   UnitX1LevelSwitch4AlarmOn: number;
   UnitX1LevelSwitch4AlarmId: number;
   UnitX1LevelSwitchDescription4: string;
   UnitX1LevelSwitchShow4: number;
   UnitX1LevelSwitchNormalClosed4: number;
   UnitProcessView: number;
   UnitIntrusionShow: number;
   UnitIntrusionOn: number;
   UnitIntrusionAlarmId: number;
   UnitPinCode: string;
   UnitX1GPRSIP: string;
   UnitX1GPRSFirewallIP: string;
   UnitX1GPRSListeningPort: number;
   UnitX1GPRSAPN: string;
   UnitX1SensorDelay: number;
   UnitX1ConverterStatus: number;
   UnitX1PingInterface: number;
   UnitInternalTemperatureShow: number;
   UnitSignalStrengthShow: number;
   UnitBERShow: number;
   UnitSWVersionMainBoard: string;
   UnitHWVersionMainBoard: string;
   UnitSWVersionTDRBoard: string;
   UnitHWVersionTDRBoard: string;
   UnitBatteryVoltageShow: number;
   UnitBatteryVoltageAlarm: number;
   UnitBatteryVoltageAlarmId: number;
   UnitUpdateAtNextSchedule: number;
   UnitUpdateAtNextSchedulePath: string,
   UnitGPSLongitude: number;
   UnitGPSLatitude: number;
   UnitCreationDate: string;
   UnitChangeDate: string;
   UnitVALevelShow: number;
   UnitCategory: number;
   UnitModules: string;
   UnitTimeZone: string;
   UnitShowCustomAlarmLow1: number;
   UnitShowCustomAlarmHigh1: number;
   UnitShowCustomAlarmId1: number;
   UnitShowCustomAlarmLow2: number;
   UnitShowCustomAlarmHigh2: number;
   UnitShowCustomAlarmId2: number;
   UnitShowCustomAlarmLow3: number;
   UnitShowCustomAlarmHigh3: number;
   UnitShowCustomAlarmId3: number;
   UnitShowCustomAlarmLow4: number;
   UnitShowCustomAlarmHigh4: number;
   UnitShowCustomAlarmId4: number;
   UnitWLANSSID: string;
   UnitWLANpassword: string;
   UnitWLANChannel: number;
   UnitSMSNumber: string;
   UnitSectionId: number;
   UnitDisabledUntil: string;
   UnitDisabledAlarmId: number;
   UnitMeasureInterval: number;
   UnitAnalogCount: number;
   UnitPTCount: number;
   UnitSwitchCount: number;
   UnitFormulaCount: number;
   UnitScaleLeft: number;
   UnitScaleRight: number;
   UnitCommunicationErrorDelayMinutes: number;
   UnitSWVersionGPRS: string;
   UnitDeleted: string;
   UnitWLANEnabled: number;
}

/**
 * GetUnitGroups
 */
export interface GetUnitGroupsResponse {
  GroupId: number;
  CustomerID: number;
  ParentGroupId: number;
  Description: string;
  SortOrder: number;
  Comment: string;
}

/**
 * GetUnitsInfo
 */
export interface GetUnitsInfoResponse {
  AlarmEventTime?: string;
  CustomerID: number;
  RowNum: number;
  TotalRows: number;
  UnitAdress: string;
  UnitAlarmActive: number;
  UnitCommunicationErrorCounter: number;
  UnitCommunicationErrorCounterStart: string;
  UnitCommunicationStarted: string;
  UnitContinousReading: number;
  UnitDescription: string;
  UnitDisabled: number;
  UnitGPSLatitude: number;
  UnitGPSLongitude: number;
  UnitID: number;
  UnitLastCommunicationErrorStart: string;
  UnitLastConnection: string;
  UnitLastSuccessConnection: string;
  UnitLocation: string;
  UnitName: string;
  UnitPort: number;
  UnitSchedule: number;
  UnitSerialNumber: string;
  UnitType: number;
}

/**
 * GetUnitType
 */
export interface GetUnitTypeResponse {
  MinAlarmEventStatus?: number;
  NoOfAlarms: number;
  NoOfUnits: number;
  NoOfUnitsWithAlarm: number;
  UnitType: string;
  UnitTypes: string;
}

/**
 * GetUnitsShowValue
 */
export interface GetUnitsShowValueResponse {
  Analog?: UnitAnalog[];
  BatteryVAlarm: number;
  BatteryVMax: number;
  BatteryVMin: number;
  BatteryVUnit: string;
  Channels?: UnitChannel[];
  Formula?: UnitFormula[];
  PT?: UnitPT[];
  Switch?: UnitSwitch[];
  UnitBERShow: number;
  UnitBatteryVoltageShow: number;
  UnitID: number;
  UnitInternalTemperatureAlarm: string;
  UnitInternalTemperatureHigh: string;
  UnitInternalTemperatureMin: string;
  UnitInternalTemperatureShow: number;
  UnitInternalTemperatureUnit: string;
  UnitIntrusionShow: number;
  UnitSignalStrengthAlarm: string;
  UnitSignalStrengthHigh: string;
  UnitSignalStrengthMin: string;
  UnitSignalStrengthShow: number;
  UnitSignalStrengthUnit: string;
  UnitVALevelShow: number;
}

export interface UnitAnalog {
  AnalogAlarmHigh: number;
  AnalogAlarmId: number;
  AnalogAlarmLow: number;
  AnalogComment: string;
  AnalogDescription: string;
  AnalogIndex: number;
  AnalogMeasureUnit: string;
  AnalogOffset: number;
  AnalogScaleFactor: number;
  AnalogShow: number;
  AnalogType: number;
  MaxAnalog: number;
  MinAnalog: number;
}

export interface UnitChannel {
  ChannelAlarmHighBattery: number;
  ChannelAlarmHighLoopResistance: number;
  ChannelAlarmLowResistancePlus: number;
  ChannelDefaultRadar: string;
  ChannelDescription: string;
  ChannelID: number;
  ChannelShow: number;
  ChannelShowBattery: number;
  ChannelShowLoopResistance: number;
  ChannelShowRadar: number;
  ChannelShowResistance: number;
  GalvVMaxValue: string;
  GalvVMinValue: string;
  GalvVUnit: string;
  LoopMaxValue: string;
  LoopMinValue: string;
  LoopUnit: string;
  ResistanceMaxValue: string;
  ResistanceMinValue: string;
  ResistanceUnit: string;
}

export interface UnitPT {
  MaxPT: number;
  MinPT: number;
  PTAlarmHigh: number;
  PTAlarmId: number;
  PTAlarmLow: number;
  PTDescription: string;
  PTIndex: number;
  PTShow: number;
  PTType: number;
}

export interface UnitSwitch {
  SwitchAlarmId: number;
  SwitchAlarmOn: number;
  SwitchDescription: string;
  SwitchIndex: number;
  SwitchNormalClosed: number;
  SwitchShow: number;
}

export interface UnitFormula {
  FormulaAlarmHigh: number;
  FormulaAlarmId: number;
  FormulaAlarmLow: number;
  FormulaComment: string;
  FormulaDescription: string;
  FormulaFormula: string;
  FormulaIndex: number;
  FormulaShow: number;
  FormulaUnit: string;
}

/**
 * GetRadar
 */
export interface GetRadarResponse {
  ChannelID: number;
  RadarBytesPerSample: number;
  RadarComment: string;
  RadarData: string;
  RadarDateTime: string;
  RadarImpedance: number;
  RadarModuleId: string;
  RadarNumberOfSamples: number;
  RadarPulseAmplification: number;
  RadarPulseLengthns: number;
  RadarStartPosition: number;
  RadarUserName: string;
  RadarnsPerSample: number;
  UnitID: number;
}

/**
 * GetRadarDates
 */
export interface GetRadarDatesResponse {
  RadarDateTime: string;
}

/**
 * GetAllMarks
 */
export interface GetAllMarksResponse {
  ChannelID: number;
  Marker: Marker[];
  UnitID: number;
}

export interface Marker {
  MarkComment: string;
  MarkID: number;
  MarkLocked: 'Y' | 'N';
  MarkPictureId: number;
  MarkPositionMeter: number;
  MarkPulsSpeed: number;
  MarkText: string;
}

/**
 * GetKPIAlarms
 */
export interface GetKPIAlarmsResponse {
  AlarmDate: string;
  Count: number;
}

/**
 * GetKPIBattery
 */
export interface GetKPIBatteryResponse {
  D: KPIBatteryQuantity[];
  DataBatteryVoltage: number;
  Status: string;
}
interface KPIBatteryQuantity {
  NoOffUnits: number;
}

/**
 * GetKPISwitch
 */
export interface GetKPISwitchResponse {
  Switch: KPISwitch[];
  UnitID: number;
  UnitName: string;
}
export interface KPISwitch {
  DataPTValue: number;
  DataSwitchValue: number;
  SwitchDescription: string;
  SwitchIndex: number;
  SwitchNormalClosed: number;
}

/**
 * GetGISData
 */
export interface GetGISDataResponse {
  features: GeoFeature[];
  name: string;
  type: string;
}
export interface GeoFeature {
  geometry: FeatureGeometry;
  properties: FeatureProperties;
  type: string;
}
export interface FeatureGeometry {
  coordinates: number[][][];
  type: string;
}
export interface FeatureProperties {
  appln: string;
  id: number;
  layer: string;
  littera: string;
  status: number;
  typ_id: number;
}

/**
 * GetReportParkingUsage
 */
export interface GetReportParkingUsageResponse {
  CurrentParkingUsages: number;
  Day: number;
  DayFree: number;
  Name: string;
  Night: number;
  NightFree: number;
  NoOfParkingPlaces: number;
  ParkingCount: number;
  UsageMinutes: number;
}

/**
 * GetWeather
 */
export interface WeatherResponse {
  cloudcover: number;
  conditions: string;
  datetime: string;
  datetimeEpoch: number;
  dew: number;
  feelslike: number;
  humidity: number;
  icon: string;
  precip: number;
  precipprob: number;
  preciptype: string[];
  pressure: number;
  severerisk: number;
  snow: number;
  snowdepth: number;
  solarenergy: number;
  solarradiation: number;
  source: string;
  temp: number;
  uvindex: number;
  visibility: number;
  winddir: number;
  windgust: number;
  windspeed: number;
}
export interface GetWeatherResponse extends WeatherResponse {
  id: number;
  description: string;
  feelslikemax: number;
  feelslikemin: number;
  hours: WeatherResponse[];
  moonphase: number;
  precipcover: number;
  sunrise: string;
  sunriseEpoch: number;
  sunset: string;
  sunsetEpoch: number;
  tempmax: number;
  tempmin: number;
}

export interface GetUserDataResponse {
  UserName: string;
  ID: string;
  Type: string;
  Default: string;
  Data: string;
  CreationDate: string;
  ChangeDate: string;
}

export interface GetAllAlarmActionResponse {
  AlarmID: number;
  CustomerID: number;
  AlarmDescription: string;
}

export interface GetAlarmActionResponse {
  AlarmID: number;
  CustomerID: number;
  AlarmDescription: string;
  AlarmStartTime: string;
  AlarmEndTime: string;
  AlarmSMSNumber: string;
  AlarmSMSText: string;
  AlarmSMSType: string;
  AlarmMailReceiver: string;
  AlarmMailSubject: string;
  AlarmMailMessage: string;
  AlarmExecuteFile: string;
  AlarmAlternativeTask: number;
  AlarmSMSInformationType: number;
  AlarmRelayCardAddress: string;
  AlarmRelayCardport: number;
  AlarmRelayCardUser: string;
  AlarmRelayCardPassword: string;
  AlarmRelayCardRelays: number;
  AlarmSMSAck: number;
  AlarmMailAck: number;
  AlarmConfirmFile: string;
  AlarmRelayCardAck: number;
  AlarmRelayCardRelaysOff: number;
  AlarmRepeatTime: number;
  AlarmSMSExecute: number;
  AlarmMailExecute: number;
  AlarmRelayCardExecute: number;
}

export interface GetChannelResponse {
  UnitID: number,
  ChannelID: number,
  ChannelDescription: string,
  ChannelComment: string,
  ChannelAlarmActive: number,
  ChannelAlarmRetriggerTime: number,
  ChannelShowResistance: number,
  ChannelShowLoopResistance: number,
  ChannelShowBattery: number,
  ChannelShowRadar: number,
  ChannelDefaultRadar: string,
  ChannelAlarmAutoRadar: number,
  ChannelRadarActive: number,
  ChannelStartupDelay: number,
  ChannelStartPositionMeter: number,
  ChannelEndPositionMeter: number,
  ChannelAlarmStartPositionMeter: number,
  ChannelAlarmEndPositionMeter: number,
  ChannelRadarFromZero: number,
  ChannelPulsSpeed: number,
  ChannelMeasureCount: number,
  ChannelnsPerSample: number,
  ChannelRadarImpedance: number,
  ChannelAlarmLowResistancePlus: number,
  ChannelAlarmHighResistancePlus: number,
  ChannelAlarmLowResistanceMinus: number,
  ChannelAlarmHighResistanceMinus: number,
  ChannelAlarmResistancePlusId: number,
  ChannelAlarmResistanceMinusId: number,
  ChannelAlarmResistanceId3: number,
  ChannelAlarmResistanceId4: number,
  ChannelAlarmHighLoopResistance: number,
  ChannelAlarmLoopResistanceId: number,
  ChannelAlarmLowBattery: number,
  ChannelAlarmHighBattery: number,
  ChannelAlarmBatteryId: number,
  ChannelAlarmBatteryId2: number,
  ChannelAlarmLowRadarPercent: number,
  ChannelAlarmHighRadarPercent: number,
  ChannelAlarmRadarId: number,
  ChannelAlarmRadarId2: number,
  ChannelAlarmScheduledDate: string,
  ChannelAlarmScheduledId: number,
  ChannelAlarmScheduledText: string,
  ChannelFilterString: string,
  ChannelChangeDate: string,
  ChannelCreationDate: string,
  ChannelAlarmRadarLastPosition: number,
  ChannelPulseMeasure: number,
  ChannelPulseLengthns: number,
  ChannelPulseAmplification: number,
  ChannelSensorWire: number,
  ChannelSerialId: string,
  ChannelShow: number,
  ChannelAlarmEarthResistanceKohm: number,
  ChannelAlarmEarthResistanceId: number
}
