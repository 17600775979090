import { getUnitStatus } from '@wisionmonorepo/core-device-support/src/status';
import { GetAlarmsResponse, GetUnitsByPosResponse, GetUnitTypeResponse } from '@wisionmonorepo/api-client-v1/src/responses';
import { i18n } from './i18n';
import { PARKING_UNITS, UNIT_TYPE_NAME } from '@wisionmonorepo/core-device-support/src/unitTypes';
import { UnitStatus } from '@wisionmonorepo/core-device-support/src/enums';

// Dashboard filter settings
export type DashboardFilterSettings = {
  [id in string]: FilterSettings;
};

// Dashbord text search
export type textSearch = {
  [id in string]: string;
};

export interface FilterSettings {
  alarmTypes: string[];
  unitStatus: UnitStatus;
  unitTypes: GetUnitTypeResponse[];
  textSearch: string;
}

export interface FilterOverrides {
  alarmTypes?: string[];
  ignoreSelected?: boolean;
  searchTerm?: string;
  units?: GetUnitsByPosResponse[];
  unitStatus?: UnitStatus;
  unitTypes?: GetUnitTypeResponse[];
}

const defaultUnitStatus: UnitStatus = {
  alarm: false,
  inactive: false,
  ok: false,
  problem: false,
};

export const defaultFilterSettings: FilterSettings = {
  unitStatus: defaultUnitStatus,
  unitTypes: [],
  alarmTypes: [],
  textSearch: '',
};

export const alarmStatusFilter = {
  unitStatus: {
    alarm: true,
    inactive: false,
    ok: false,
    problem: false
  },
};

export const parkingUnitFilter = {
  unitTypes: [{
    UnitTypes: PARKING_UNITS.join(','),
  }],
};

export const getFilterSettings = (
  alarmTypes?: string[],
  unitStatus?: UnitStatus,
  unitTypes?: GetUnitTypeResponse[]
): FilterOverrides => {
  return {
    alarmTypes: alarmTypes ? alarmTypes : defaultFilterSettings.alarmTypes,
    unitStatus: unitStatus ? unitStatus : defaultFilterSettings.unitStatus, 
    unitTypes: unitTypes ? unitTypes : defaultFilterSettings.unitTypes, 
  };
};

export const filterUnits = (
  units: GetUnitsByPosResponse[],
  overrides: FilterOverrides = {},
  filterSettings: FilterSettings = defaultFilterSettings,
): GetUnitsByPosResponse[] => {
  const { unitStatus, unitTypes, alarmTypes, searchTerm } = overrides;

  const filterUnitStatus: UnitStatus = unitStatus || filterSettings.unitStatus;
  const filterUnitTypes: GetUnitTypeResponse[] = unitTypes || filterSettings.unitTypes;
  const filterAlarmTypes: string[] = alarmTypes || filterSettings.alarmTypes;
  const filterSearchTerm: string = searchTerm || filterSettings.textSearch;

  return new Filter(
    filterUnitTypes,
    filterUnitStatus,
    filterAlarmTypes,
    filterSearchTerm
  ).getFilteredUnits(units);
};

export class Filter {
  private readonly allStatus: boolean;
  private readonly searchWord: string;
  private readonly selectedAlarmTypes: string[];
  private readonly selectedStatus: UnitStatus;
  private readonly selectedTypes: GetUnitTypeResponse[];

  public constructor(
    selectedTypes: GetUnitTypeResponse[],
    selectedStatus: UnitStatus,
    selectedAlarmTypes: string[],
    searchWord: string,
  ) {
    this.selectedTypes = selectedTypes;
    this.selectedStatus = selectedStatus;
    this.selectedAlarmTypes = selectedAlarmTypes;
    this.searchWord = !searchWord ? '' : searchWord
      .trim()
      .toLowerCase();
    this.allStatus =
      (selectedStatus.alarm && selectedStatus.problem && selectedStatus.inactive && selectedStatus.ok) ||
      (!selectedStatus.alarm && !selectedStatus.problem && !selectedStatus.inactive && !selectedStatus.ok);
  }

  public getFilteredAlarms(alarms: GetAlarmsResponse[]): GetAlarmsResponse[] {
    return alarms.filter(this.isMatch.bind(this));
  }

  public getFilteredUnits(units: GetUnitsByPosResponse[]): GetUnitsByPosResponse[] {
    return units.filter(this.isMatch.bind(this));
  }

  private isAlarmMatch(item: GetUnitsByPosResponse | GetAlarmsResponse): boolean {
    const radix = 10;
    const selectedAlarmTypes = this.selectedAlarmTypes.map((alarmType) =>
      parseInt(alarmType, radix));

    if (this.selectedAlarmTypes.length === 0) {
      return true;
    }

    if ('AlarmID' in item) {
      return selectedAlarmTypes.includes(item.AlarmType);
    }

    return selectedAlarmTypes.some((alarmType) =>
      item.AlarmEvents.map((alarmEvent) => alarmEvent.AlarmType).includes(alarmType));
  }

  private readonly isCategoryMatch = (
    data: GetAlarmsResponse | GetUnitsByPosResponse,
    unitType: GetUnitTypeResponse
  ): boolean => {
    const unitTypes = unitType
      .UnitTypes
      .replace(/^\(|\)$/g, '')
      .split(',');

    if ('AlarmID' in data) {

      return unitTypes
        .some((category: string) => category === (data.Units[0].UnitType).toString());
    }

    return unitTypes
      .some((category: string) => category === data.UnitType.toString());
  };

  private isMatch(data: GetUnitsByPosResponse | GetAlarmsResponse): boolean {
    if ('AlarmID' in data) {
      return this.isSearchWordMatch(data) &&
        this.isTypeMatch(data) &&
        this.isAlarmMatch(data);
    }

    return this.isSearchWordMatch(data) &&
      this.isStatusMatch(data) &&
      this.isTypeMatch(data) &&
      this.isAlarmMatch(data);
  }

  private isSearchWordMatch(data: GetAlarmsResponse | GetUnitsByPosResponse): boolean {
    if (!this.searchWord) {
      return true;
    }

    if ('AlarmID' in data) {
      return this.isTextMatch(data.AlarmEventText) ||
        this.isTextMatch(i18n.t(`alarmtypes.${data.AlarmType}`).toString()) ||
        this.isTextMatch(UNIT_TYPE_NAME[data.Units[0].UnitType]);
    }

    return this.isTextMatch(data.UnitName) ||
      this.isTextMatch(data.UnitLocation) ||
      this.isTextMatch(data.UnitType.toString());

  }

  private isStatusMatch(unit: GetUnitsByPosResponse): boolean {
    let result = true;
    if (!this.allStatus) {
      const unitStatus: UnitStatus = getUnitStatus(unit);
      result =
        (this.selectedStatus.alarm && unitStatus.alarm) ||
        (this.selectedStatus.ok && unitStatus.ok) ||
        (this.selectedStatus.problem && unitStatus.problem) ||
        (this.selectedStatus.inactive && unitStatus.inactive);
    }

    return result;
  }

  private isTextMatch(text: string): boolean {
    if (!text) {
      return false;
    }

    const cleanText: string = text
      .trim()
      .toLowerCase();

    return cleanText.indexOf(this.searchWord) > -1;
  }

  private isTypeMatch(data: GetUnitsByPosResponse | GetAlarmsResponse): boolean {
    if (this.selectedTypes.length === 0) {
      return true;
    }

    return this
      .selectedTypes
      .some((unitType: GetUnitTypeResponse) =>
        this.isCategoryMatch(data, unitType));
  }
}
