export interface DateInterval {
  quantity: number;
  unit: DateIntervalUnits;
}

const RESISTANCE_LEVEL_1 = 0;
// const RESISTANCE_LEVEL_2 = 1;
// const RESISTANCE_LEVEL_3 = 2;
// const RESISTANCE_LEVEL_4 = 3;
const LOOP_ERROR = 4;
// const LOOP_INTERUPT = 5;
const GALVANIC_LEVEL_1 = 6;
// const GALVANIC_LEVEL_2 = 7;
// const RADARPERCENT_LEVEL_1 = 8;
// const RADARPERCENT_LEVEL_2 = 9;
// const ANALOGA_LARM_LOW_1 = 10;
// const ANALOGA_LARM_HIGH_1 = 11;
// const ANALOGA_LARM_LOW_2 = 12;
// const ANALOGA_LARM_HIGH_2 = 13;
const ANALOGA_LARM_LOW_3 = 14;
// const ANALOGA_LARM_HIGH_3 = 15;
const ANALOGA_LARM_LOW_4 = 16;
// const ANALOGA_LARM_HIGH_4 = 17;
// const SCHEDULED_ALARM = 18;
const COMMUNICATION_ERROR = 19;
const PT_ALARM_LOW_1 = 20;
// const PT_ALARM_HIGH_1 = 21;
// const PT_ALARM_LOW2_ = 22;
// const PT_ALARM_HIGH_2 = 23;
// const PT_ALARM_LOW3_ = 24;
// const PT_ALARM_HIGH_3 = 25;
// const PT_ALARM_LOW4_ = 26;
// const PT_ALARM_HIGH_4 = 27;
const BATTERY_VOLTAGE_ALARM = 28;
const LEVEL_SWITCH_1 = 29;
const LEVEL_SWITCH_2 = 30;
// const LEVEL_SWITCH_3 = 31;
// const LEVEL_SWITCH_4 = 32;
const INTRUSION_ALARM = 34;
const FORMULA_1 = 39;
// const FORMULA_2 = 40;
// const FORMULA_3 = 41;
// const FORMULA_4 = 42;
const DISABLED = 43;

export const alarmTypes: number[] = [
  RESISTANCE_LEVEL_1,
  //  RESISTANCE_LEVEL_2,
  //  RESISTANCE_LEVEL_3,
  //  RESISTANCE_LEVEL_4,
  LOOP_ERROR,
  //  LOOP_INTERUPT,
  GALVANIC_LEVEL_1,
  //  GALVANIC_LEVEL_2,
  //  RADARPERCENT_LEVEL_1,
  //  RADARPERCENT_LEVEL_2,
  //  ANALOGA_LARM_LOW_1,
  //  ANALOGA_LARM_HIGH_1,
  //  ANALOGA_LARM_LOW_2,
  //  ANALOGA_LARM_HIGH_2,
  ANALOGA_LARM_LOW_3,
  //  ANALOGA_LARM_HIGH_3,
  ANALOGA_LARM_LOW_4,
  //  ANALOGA_LARM_HIGH_4,
  //  SCHEDULEDALARM,
  COMMUNICATION_ERROR,
  PT_ALARM_LOW_1,
  //  PT_ALARM_HIGH_1,
  //  PT_ALARM_LOW_2,
  //  PT_ALARM_HIGH_2,
  //  PT_ALARM_LOW_3,
  //  PT_ALARM_HIGH_3,
  //  PT_ALARM_LOW_4,
  //  PT_ALARM_HIGH_4,
  BATTERY_VOLTAGE_ALARM,
  LEVEL_SWITCH_1,
  LEVEL_SWITCH_2,
  //  LEVEL_SWITCH_3,
  //  LEVEL_SWITCH_4,
  INTRUSION_ALARM,
  FORMULA_1,
  //  FORMULA_2,
  //  FORMULA_3,
  //  FORMULA_4,
  DISABLED,
];

export enum AlarmTypes {
  RESISTANCE_LEVEL_1,
  RESISTANCE_LEVEL_2,
  RESISTANCE_LEVEL_3,
  RESISTANCE_LEVEL_4,
  LOOP_ERROR,
  LOOP_INTERUPT,
  GALVANIC_LEVEL_1,
  GALVANIC_LEVEL_2,
  RADARPERCENT_LEVEL_1,
  RADARPERCENT_LEVEL_2,
  ANALOGA_LARM_LOW_10,
  ANALOGA_LARM_HIGH_1,
  ANALOGA_LARM_LOW_2,
  ANALOGA_LARM_HIGH_2,
  ANALOGA_LARM_LOW_3,
  ANALOGA_LARM_HIGH_3,
  ANALOGA_LARM_LOW_4,
  ANALOGA_LARM_HIGH_4,
  SCHEDULED_ALARM,
  COMMUNICATION_ERROR,
  PT_ALARM_LOW_1,
  PT_ALARM_HIGH_1,
  PT_ALARM_LOW2_2,
  PT_ALARM_HIGH_2,
  PT_ALARM_LOW3_4,
  PT_ALARM_HIGH_3,
  PT_ALARM_LOW4_6,
  PT_ALARM_HIGH_4,
  BATTERY_VOLTAGE_ALARM,
  LEVEL_SWITCH_1,
  LEVEL_SWITCH_2,
  LEVEL_SWITCH_3,
  LEVEL_SWITCH_4,
  INTRUSION_ALARM = 34,
  FORMULA_1 = 39,
  FORMULA_2,
  FORMULA_3,
  FORMULA_4,
  DISABLED,
  RESISTANCE_EARTH = 52
}

export enum ChartSeries {
  DataAnalog1Value = 'DataAnalog1Value',
  DataAnalog2Value = 'DataAnalog2Value',
  DataAnalog3Value = 'DataAnalog3Value',
  DataAnalog4Value = 'DataAnalog4Value',
  DataCh1BatteryValue = 'DataCh1BatteryValue',
  DataCh2BatteryValue = 'DataCh2BatteryValue',
  DataCh3BatteryValue = 'DataCh3BatteryValue',
  DataCh4BatteryValue = 'DataCh4BatteryValue',
  DataCh1GalvanicResistance = 'DataCh1GalvanicResistance',
  DataCh2GalvanicResistance = 'DataCh2GalvanicResistance',
  DataCh3GalvanicResistance = 'DataCh3GalvanicResistance',
  DataCh4GalvanicResistance = 'DataCh4GalvanicResistance',
  DataCh1LoopResistance = 'DataCh1LoopResistance',
  DataCh2LoopResistance = 'DataCh2LoopResistance',
  DataCh3LoopResistance = 'DataCh3LoopResistance',
  DataCh4LoopResistance = 'DataCh4LoopResistance',
  DataCh1Resistance = 'DataCh1Resistance',
  DataCh2Resistance = 'DataCh2Resistance',
  DataCh3Resistance = 'DataCh3Resistance',
  DataCh4Resistance = 'DataCh4Resistance',
  DataBatteryVoltage = 'DataBatteryVoltage',
  DataBER = 'DataBER',
  DataLevelFormula1 = 'DataLevelFormula1',
  DataLevelFormula2 = 'DataLevelFormula2',
  DataLevelFormula3 = 'DataLevelFormula3',
  DataLevelFormula4 = 'DataLevelFormula4',
  DataInternalTemperature = 'DataInternalTemperature',
  DataIntrusion = 'DataIntrusion',
  DataLevelSwitch1 = 'DataLevelSwitch1',
  DataLevelSwitch2 = 'DataLevelSwitch2',
  DataLevelSwitch3 = 'DataLevelSwitch3',
  DataLevelSwitch4 = 'DataLevelSwitch4',
  DataPT1Value = 'DataPT1Value',
  DataPT2Value = 'DataPT2Value',
  DataPT3Value = 'DataPT3Value',
  DataPT4Value = 'DataPT4Value',
  DataSignalStrength = 'DataSignalStrength',
}

export enum DateIntervalUnits {
  Hour = 'hour',
  Day = 'day',
}

export interface UnitStatus {
  alarm: boolean;
  inactive: boolean;
  ok: boolean;
  problem: boolean;
}

export enum UnitStatusNames {
  Alarm = 'alarm',
  Inactive = 'inactive',
  Ok = 'ok',
  Problem = 'problem',
}

export enum UnitColor {
  alarm = '#C41539',
  inactive = '#FFFFFF',
  ok = '#4BD3AF',
  problem = '#ff9900',
}

export interface clusterStyle {
  url: string,
  height: number,
  width: number
}

export const clusterIconSizes: number[] = [
  53,
  56,
  66,
  78,
  90
];

//Cluster colors based on their severity; index 0 has highest priority
export const clusterColors: string[] = [
  'red',
  'orange',
  'green'
];

export const unitUpdateIntegerFields: string[] = [
  'UNITTYPE',
  'UNITSCHEDULE',
  'UNITCONTINOUSREADING',
  'UNITGROUPID',
  'UNITPORT',
  'UNITCURRENTPORT',
  'UNITREPLYSOCKETPORT',
  'UNITREPLYSOCKETPORT2',
  'UNITCOMMUNICATIONALARMID',
  'UNITANALOGALARMRETRIGGERTIME',
  'UNITINTRUSIONSHOW',
  'UNITINTRUSIONON',
  'UNITINTRUSIONALARMID',
  'UNITPINCODE',
  'UNITX1GPRSLISTENINGPORT',
  'UNITX1SENSORDELAY',
  'UNITX1CONVERTERSTATUS',
  'UNITX1PINGINTERFACE',
  'UNITINTERNALTEMPERATURESHOW',
  'UNITSIGNALSTRENGTHSHOW',
  'UNITBERSHOW',
  'UNITBATTERYVOLTAGESHOW',
  'UNITBATTERYVOLTAGEALARM',
  'UNITBATTERYVOLTAGEALARMID',
  'UNITUPDATEATNEXTSCHEDULE',
  'UNITVALEVELSHOW',
  'UNITCATEGORY',
  'UNITSECTIONID',
  'UNITDISABLEDUNTIL',
  'UNITDISABLEDALARMID',
  'UNITMEASUREINTERVAL',
  'UNITWLANCHANNEL',
  'UNITACTIVEFTP',
  'UNITDISABLED',
  'UNITALARMACTIVE',
];

export const unitUpdateStringFields: string[] = [
  'UNITNAME',
  'UNITDESCRIPTION',
  'UNITLOCATION',
  'UNITCOMMENT',
  'UNITADRESS',
  'UNITCURRENTADRESS',
  'UNITCURRENTMASK',
  'UNITCURRENTGATEWAY',
  'UNITREPLYSOCKETADRESS',
  'UNITREPLYSOCKETADRESS2',
  'UNITMASKALARMCONNECTION',
  'UNITX1GPRSIP',
  'UNITX1GPRSFIREWALLIP',
  'UNITX1GPRSAPN',
  'UNITUPDATEATNEXTSCHEDULEPATH',
  'UNITTIMEZONE',
  'UNITWLANSSID',
  'UNITWLANPASSWORD',
  'UNITSMSNUMBER',
];

export const unitUpdateFloatFields: string[] = [
  'UNITGPSLONGITUDE',
  'UNITGPSLATITUDE',
];

export const languages: { [languageId: number]: string } = {
  44: 'en',
  45: 'da',
  46: 'sv',
  49: 'de',
  86: 'zh',
  966: 'ar',
};

export const channelSeries: ChartSeries[] = [
  ChartSeries.DataCh1LoopResistance,
  ChartSeries.DataCh2LoopResistance,
  ChartSeries.DataCh3LoopResistance,
  ChartSeries.DataCh4LoopResistance,
];

export const analogSeries: ChartSeries[] = [
  ChartSeries.DataAnalog1Value,
  ChartSeries.DataAnalog2Value,
  ChartSeries.DataAnalog3Value,
  ChartSeries.DataAnalog4Value,
];

export const ptSeries: ChartSeries[] = [
  ChartSeries.DataPT1Value,
  ChartSeries.DataPT2Value,
  ChartSeries.DataPT3Value,
  ChartSeries.DataPT4Value,
];

export const channelLoopResistanceSeries: ChartSeries[] = [
  ChartSeries.DataCh1LoopResistance,
  ChartSeries.DataCh2LoopResistance,
  ChartSeries.DataCh3LoopResistance,
  ChartSeries.DataCh4LoopResistance,
];

export const channelGalvanicSeries: ChartSeries[] = [
  ChartSeries.DataCh1GalvanicResistance,
  ChartSeries.DataCh2GalvanicResistance,
  ChartSeries.DataCh3GalvanicResistance,
  ChartSeries.DataCh4GalvanicResistance,
];

export const switchSeries: ChartSeries[] = [
  ChartSeries.DataLevelSwitch1,
  ChartSeries.DataLevelSwitch2,
  ChartSeries.DataLevelSwitch3,
  ChartSeries.DataLevelSwitch4,
];

export const formulaSeries: ChartSeries[] = [
  ChartSeries.DataLevelFormula1,
  ChartSeries.DataLevelFormula2,
  ChartSeries.DataLevelFormula3,
  ChartSeries.DataLevelFormula4,
];

export const dateIntervals: DateInterval[] = [
  {
    quantity: 1,
    unit: DateIntervalUnits.Hour,
  },
  {
    quantity: 6,
    unit: DateIntervalUnits.Hour,
  },
  {
    quantity: 1,
    unit: DateIntervalUnits.Day,
  },
  {
    quantity: 3,
    unit: DateIntervalUnits.Day,
  },
  {
    quantity: 7,
    unit: DateIntervalUnits.Day,
  },
  {
    quantity: 30,
    unit: DateIntervalUnits.Day,
  },
  //GÖMA DESSA VAL FÖR V1
  // {
  //   quantity: 60,
  //   unit: DateIntervalUnits.Day,
  // },
  // {
  //   quantity: 180,
  //   unit: DateIntervalUnits.Day,
  // },
  // {
  //   quantity: 365,
  //   unit: DateIntervalUnits.Day,
  // },
];

// Priority order for temp values. The first is of highest priority
export const tempPriorityOrder = [
  'PT 1',
  'PT 2',
  'PT 3',
  'PT 4',
  'PT1000 1',
  'PT1000 2',
  'Temp',
  'chartdata.datainternaltemperature',
  'Int. temp.',
  'Int Temp',
  'Temp intern'
];

export const criticalAlarmTypes = [0, 30];
