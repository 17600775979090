import useApiClient, { useRequestInit } from './apiClient';
import { ServiceBinding } from '@core/lib/serviceBindings';
import { UserProfile } from '@user-service/features/userProfile/model/userProfile';
import { Organization } from '@user-service/features/organization/model';
import { 
  BillingInfo, 
  ContactInfo 
} from '@admin-service/features/organization/model';

export const useAdminApi = (
  baseUrl: string,
  serviceBinding: ServiceBinding | null = null,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(serviceBinding?.fetch.bind(serviceBinding));
  const getRequestInit = useRequestInit(serviceBinding?.fetch, sessionCookie);

  const cacheKeys = {
    users: 'users',
    organizations: 'organizations'
  };

  const getUserProfilesByOrganizationId = async (organizationId: number | null = null) =>
    await apiClient<UserProfile[]>(
      `${baseUrl}/api/v1/admin/profilesByOrgId${organizationId ? `?organizationId=${organizationId}` : ''}`,
      getRequestInit({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    );

  const deleteUserProfileFromOrganization = async (username: string, organizationId: number) =>
    await apiClient(
      `${baseUrl}/api/v1/admin/profile`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, organizationId }),
      }),
      false
    );

  const updateUserRole = async (username: string, organizationId: number, roleId: number) =>
    await apiClient(
      `${baseUrl}/api/v1/admin/updateUserRole`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, organizationId, roleId }),
      }),
      false
    );

  const updateUserPassword = async (username: string, newPassword: string) =>
    await apiClient(
      `${baseUrl}/api/v1/admin/updateUserPassword`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, newPassword }),
      }),
      true,
      true
    );

  const updateUserProfile = async (username: string, userProfile: Partial<UserProfile>) =>
    await apiClient(
      `${baseUrl}/api/v1/admin/updateUserProfile`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, userProfile }),
      }),
      false
    );

  const saveUserAvatar = async (username: string, image: File) => {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('username', username);

    return await apiClient<string>(
      `${baseUrl}/api/v1/admin/saveUserAvatar`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/text',
        },
        body: formData,
      }),
      true,
      true
    );
  };

  const deleteUserAvatar = async (username: string) =>
    await apiClient(
      `${baseUrl}/api/v1/admin/deleteUserAvatar`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/text',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      }),
      true,
      true
    );

  const addUser = async (username: string, name: string, organizationId: number) =>
    await apiClient<UserProfile>(
      `${baseUrl}/api/v1/admin/addUser`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, name, organizationId }),
      })
    );

  const getOrganizations = async () =>
    await apiClient<Organization[]>(
      `${baseUrl}/api/v1/admin/getOrganizations`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
    );
  
  const getBillingInfo = async (organizationId: number) => 
    await apiClient<BillingInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/billingInfo`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
    );
  
  const getContactInfo = async (organizationId: number) => 
    await apiClient<ContactInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/contactInfo`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
    );

  const updateBillingInfo = async (organizationId: number, billingInfo: Partial<BillingInfo>) => 
    await apiClient<ContactInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/billingInfo`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...billingInfo }),
      })
    );

  const updateContactInfo = async (organizationId: number, contactInfo: Partial<ContactInfo>) => 
    await apiClient<ContactInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/contactInfo`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...contactInfo }),
      })
    );

  const saveOrganizationAvatar = async (organizationId: number, image: File) => {
    return await apiClient<string>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/avatar`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/text',
          'Content-Type': image.type,
        },
        body: image,
      }),
      true,
      true
    );
  };

  const deleteOrganizationAvatar = async (organizationId: number) => 
    await apiClient<ContactInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/avatar`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      true,
      true
    );
  
  const updateOrganizationName = async (organizationId: number, name: string) => 
    await apiClient<ContactInfo>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/name`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name })
      }),
      false
    );

  const addOrganization = async (parentOrganizationId: number, organizationName: string) => 
    await apiClient<Organization>(
      `${baseUrl}/api/v1/admin/organization/add`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parentOrganizationId, organizationName })
      })
    );

  const deleteOrganization = async (organizationId: number) => 
    await apiClient<Organization>(
      `${baseUrl}/api/v1/admin/organization/${organizationId}/delete`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      false
    );

  return {
    cacheKeys,
    getUserProfilesByOrganizationId,
    deleteUserProfileFromOrganization,
    updateUserRole,
    updateUserProfile,
    saveUserAvatar,
    deleteUserAvatar,
    addUser,
    updateUserPassword,
    getOrganizations,
    getBillingInfo,
    getContactInfo,
    updateBillingInfo,
    updateContactInfo,
    saveOrganizationAvatar,
    deleteOrganizationAvatar,
    updateOrganizationName,
    addOrganization,
    deleteOrganization
  };
};
