import { LoginAccessResponse, Widget } from '@wisionmonorepo/api-client-v1/src/responses';

// Public interface
export interface PermissionsModel {
  gisCustomers: number[];
  parkingCustomers: number[];
}

export const createPermissions = (response: LoginAccessResponse): PermissionsModel => ({
  gisCustomers: customersWithAccess(Widget.TyrensGIS, response),
  parkingCustomers: customersWithAccess(Widget.Parking, response),
});

const customersWithAccess = (feature: Widget, response: LoginAccessResponse): number[] =>
  response
    .Customers
    .filter((c) => c.CustomerRights.some((cr) => cr.Widget === feature))
    .map((c) => c.CustomerID);
