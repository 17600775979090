import Vue from 'vue';
import { Store } from 'vuex';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import { RootState } from '@/core/store/types';
import en from '@wisionmonorepo/core/src/i18n/locales/en/translation.json';
import moment from 'moment';
import { languages } from '@wisionmonorepo/core-device-support/src/enums';

Vue.use(VueI18n);

export const i18n: VueI18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: { en },
});

const loadedLanguages: string[] = ['en']; 

const setI18nLanguage = (lang: string): string => {
  i18n.locale = lang;

  document.querySelector('html')
    ?.setAttribute('lang', lang);

  return lang;
};

export const loadLanguageAsync = async (lang: string): Promise<string> => {
  moment.locale(lang);

  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@wisionmonorepo/core/src/i18n/locales/${lang}/translation.json`)
    .then((messages: LocaleMessageObject) => {
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);

      return setI18nLanguage(lang);
    });
};

export const setUserLanguage = async (languageId: number): Promise<string> => {
  return loadLanguageAsync(!languageId ? 'en' : (languages[languageId] || 'en'));
};

export const initializeLanguage = async (store: Store<RootState>): Promise<void> => {
  const activeLanguage = store.getters['user/activeLanguage'];

  if (activeLanguage) {
    const lang: string = activeLanguage || 'en';

    await loadLanguageAsync(lang);
  } else {
    await loadLanguageAsync('en');
  }
};
