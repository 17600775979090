import { TagRestrictionId, DeviceTemplateId } from '@wisionmonorepo/wision-nfc-service/src/model/tag';
import { getErrorMessage } from '@wisionmonorepo/wision-app/src/lib/helpers';
/**
 * @overview API requests
 */

import { Session } from '@wisionmonorepo/wision-core-service/src/core/model/session';
import { endpoints } from './endpoints';
import { DeviceBase, DeviceOptions, DeviceDetails } from '@wisionmonorepo/wision-core-service/src/core/model/device';
import axios from 'axios';
import {
  LoginResponse
} from './responses';
import {
  LoginParams,
} from './params';
import { UserDTO } from '@wisionmonorepo/wision-user-service/src/features/userProfile/dto/user';

export const login = async (username: string, password: string): Promise<LoginResponse> => {
  const params: LoginParams = {
    username,
    password
  };

  try {
    const response = await axios.post<LoginResponse>(endpoints.AuthLogin, params);
    return response.data;
  } catch {
    throw new Error('Authentication failed');
  }
};

export const logout = async (): Promise<void> => {
  return await axios.get(endpoints.AuthLogout);
};

export const userSessions = async (): Promise<Array<Session>> => {
  try {
    const response = await axios.get<Array<Session>>(endpoints.AuthSessions);
    return response.data;
  } catch {
    throw new Error('Authentication failed');
  }
};

export const logoutSession = async (id: string): Promise<void> => {
  await axios.delete(`${endpoints.AuthLogoutSession}/${id}`);
};

export const uploadAvatar = async (file: File): Promise<string> => {
  const headers = { 'Content-Type': file.type };

  try {
    const response = await axios.post<string>(
      endpoints.UserProfileAvatar,
      file,
      { headers }
    );

    return response.data;
  } catch {
    throw new Error('Authentication failed');
  }
};

export const deleteAvatar = async (): Promise<boolean> => {
  try {
    const response = await axios.delete<boolean>(endpoints.UserProfileAvatar);

    return response.data;
  } catch {
    throw new Error('Authentication failed');
  }
};

export const updateUser = async (user: Partial<UserDTO>): Promise<boolean> => {
  try {
    const response = await axios.put<boolean>(endpoints.UserProfile, user);

    return response.data;
  } catch {
    throw new Error('Request failed');
  }
};

export const updateUserPassword = async (currentPassword: string, newPassword: string): Promise<boolean> => {
  try {
    const response = await axios.post<boolean>(endpoints.UserProfilePassword, { currentPassword, newPassword });

    return response.data;
  } catch {
    throw new Error('Authentication failed');
  }
};

export const fetchDevices = async (customers: Array<number>): Promise<Array<DeviceBase>> => {
  try {
    const response = await axios.get<Array<DeviceBase>>(endpoints.Devices, { params: { orgs: customers.join(',') } });

    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const fetchDeviceDetails = async(id: number): Promise<DeviceDetails> => {
  try {
    const response = await axios.get<DeviceDetails>(`${endpoints.Devices}/${id}/details`);

    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const fetchDeviceOptions = async(id: number): Promise<DeviceOptions> => {
  try {
    const response = await axios.get<DeviceOptions>(`${endpoints.Devices}/${id}/options`);

    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const fetchTagId = async(deviceId: string): Promise<string> => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_WISION_API_URL}/api/v1/nfc/tag?unit=${deviceId}`);

    if (!response.data) return '';
     
    const { tag_uuid } = response.data;
      
    return tag_uuid;
  } catch (error) {
    throw getErrorMessage(error);
  }
};

export const fetchTagTemplate = async(uuid: string): Promise<DeviceTemplateId | -1> => {
  try {
    const response = await axios.get<DeviceTemplateId>(`${process.env.VUE_APP_WISION_API_URL}/api/v1/nfc/tag/${uuid}/template`);

    if (!response.data) return -1;
     
    return response.data;
  } catch (error) {
    throw getErrorMessage(error);
  }
};

export const fetchTagRestriction = async(uuid: string): Promise<TagRestrictionId | -1> => {
  try {
    const response = await axios.get<TagRestrictionId>(`${process.env.VUE_APP_WISION_API_URL}/api/v1/nfc/tag/${uuid}/restriction`);

    if (!response.data) return -1;
     
    return response.data;
  } catch (error) {
    throw getErrorMessage(error);
  }
};

export const updateTagStatus = async(uuid: string,  restrictionId: TagRestrictionId): Promise<TagRestrictionId | -1> =>
{
  try {
    const response = await axios.put(`${process.env.VUE_APP_WISION_API_URL}/api/v1/nfc/tag/${uuid}/restriction?id=${restrictionId}`, {
      method: 'PUT',
    });
      
    if (!response.data) return -1;
     
    return restrictionId;
  } catch (error) {
    throw getErrorMessage(error);
  }
};

export const updateTagTemplate = async(uuid: string,  templateId: DeviceTemplateId): Promise<DeviceTemplateId> => {
  try {
    const response = await axios.put(`${process.env.VUE_APP_WISION_API_URL}/api/v1/nfc/tag/${uuid}/template?id=${templateId}`, {
      method: 'PUT',
    }); 
      
    if (!response.data) return 0;

    return templateId; 
  } catch (error) {
    throw getErrorMessage(error);
  }
};
