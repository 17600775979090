import Vue from 'vue';
import { debug } from '../../../../../config';

export interface ToastMessage {
  message: string,
  variant: ToastVariant,
}

export type ToastVariant = 'success' | 'info' | 'warning' | 'danger';

export const TOAST_EVENT = 'toast';

export class Toast {
  private static messageBus?: Vue;
  private static queue: ToastMessage[] = [];

  public static createToast(message: string, variant: ToastVariant): void {
    const toast: ToastMessage = { message, variant };

    if (this.messageBus) {
      this.messageBus.$emit(TOAST_EVENT, toast);
    } else {
      this.queue.push(toast);
    }
  }

  public static initialize(messageBus: Vue): void {
    this.messageBus = messageBus;

    if (debug) console.info('Flushing toast queue', this.queue);
    this.queue.forEach((toast) => {
      messageBus.$emit(TOAST_EVENT, toast);
    });
    this.queue = [];
  }
}
