import { nanoid } from 'nanoid';

export type ContainerRecord = Record<string, Container>;

export enum ReferenceType {
  Unit = 'unit',
  Location = 'location',
}

export interface Reference {
  target: string;
  name: string;
  type: ReferenceType;
}

export interface Container {
  id: string;
  name: string;
  content: Reference[];
}

export const createContainer = (
  { name, content }: { name: string, content?: Reference[] }
): Container => {
  if (!name) throw new Error('Missing parameters');

  return {
    id: nanoid(),
    name,
    content: content || [],
  };
};

export const insertReference = (
  { container, reference } : { container: Container, reference: Reference }
): Container => {
  return {
    ...container,
    content: [
      ...container.content,
      reference,
    ],
  };
};

export const deleteReference = (
  { container, target } : { container: Container, target: string }
): Container => {
  return {
    ...container,
    content: container.content.filter((ref: Reference) => ref.target != target),
  };
};

export const createReference = (
  { name, type, target }: { name: string, type: ReferenceType, target: string | number }
): Reference => {
  if (!(name || target)) throw new Error('Missing parameters');

  return {
    name,
    type,
    target: target.toString(),
  };
};

export const deleteReferences = (
  { record, target } : { record: ContainerRecord, target: string }
): ContainerRecord => {
  return Object.keys(record).reduce((acc: ContainerRecord, current: string): ContainerRecord => {
    acc[current] = deleteReference({ container: record[current], target });
    return acc;
  }, {});
};
