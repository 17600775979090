export interface ResetPassword {
  user_id: string,
  token: string
}

export const queries = {
  insert: 'INSERT INTO reset_password (user_id, token) VALUES (?, ?);',

  selectByToken: 'SELECT * FROM reset_password WHERE token=?;',

  delete: 'DELETE FROM reset_password WHERE token=?',
};
