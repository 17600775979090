import {
  GetAlarmsResponse,
  GetAllMarksResponse,
  GetGISDataResponse,
  GetKPIAlarmsResponse,
  GetKPIBatteryResponse,
  GetKPISwitchResponse,
  GetRadarDatesResponse,
  GetRadarResponse,
  GetReportParkingUsageResponse,
  GetUnitGroupsResponse,
  GetUnitsByPosResponse,
  GetUnitsInfoResponse,
  GetUnitsShowValueResponse,
  GetUnitTypeResponse,
  GetUserDataResponse,
  GetWeatherResponse,
  LoginAccessResponse,
  ResultResponse,
  GetChannelResponse,
} from './responses';

import {
  DeleteUserDataParams,
  GetAlarmsParams,
  GetAllMarksParams,
  EditMarkParams,
  DeleteMarkParams,
  GetGISDataParams,
  GetKPIAlarmsParams,
  GetKPIBatteryParams,
  GetKPISwitchParams,
  GetRadarDatesParams,
  GetRadarParams,
  GetReportParkingUsageParams,
  GetUnitGroupsParams,
  GetUnitsByPosParams,
  GetUnitsDataCSVParams,
  GetUnitsDataParams,
  GetUnitsInfoParams,
  GetUnitsShowValueParams,
  GetUnitTypeParams,
  GetUserDataParams,
  GetWeatherParams,
  LoginParams,
  SetUserDataParams,
  UnitActionParams,
  SetRelayParams,
  GetChannelParams,
  EditChannelParams,
} from './params';

import {
  formatDate,
  get,
  getChunkedList,
  getList,
  getSingle,
  HTTPMethod,
  isEmptyObject,
  isResult,
  post,
  ResponseReducer,
} from './util';

import { endpoints } from './endpoints';

export const login = async (username: string, password: string): Promise<LoginAccessResponse> => {
  const params: LoginParams = {
    username,
    password
  };

  try {
    return await post<LoginAccessResponse>(endpoints.AuthLogin, params);
  } catch {
    throw new Error('Authentication failed');
  }
};

export const logout = async (): Promise<void> => {
  await get(endpoints.AuthLogout, {});
};

export const loginAccess = async (): Promise<LoginAccessResponse> => {
  return await getSingle<LoginAccessResponse>(endpoints.LoginAccess, {});
};

export const getAlarms = async ({
  unitIds = [],
  customerIds = [],
  startDate = new Date('2010-01-01 00:00'.replace(/-/g, '/')),
  rows = 500,
}: {
  unitIds?: number[];
  customerIds?: number[];
  startDate?: Date;
  rows?: number;
}): Promise<GetAlarmsResponse[]> => {
  const params: GetAlarmsParams = {
    CustomerIDs: customerIds.join(','),
    UnitIDs: unitIds.join(','),
    Rows: rows,
    StartDate: formatDate(startDate),
  };

  return getChunkedList<GetAlarmsResponse, GetAlarmsParams>(
    endpoints.GetAlarms,
    params,
    unitIds,
    'UnitIDs',
  );
};

export const getUnitsByPos = async ({
  unitIds = [],
  customerIds = [],
  distanceKm = 100000,
  rows = 10000,
  unitGroupIds = []
}: {
  unitIds?: number[],
  customerIds?: number[];
  distanceKm?: number;
  rows?: number;
  unitGroupIds?: number[];
}): Promise<GetUnitsByPosResponse[]> => {
  const params: GetUnitsByPosParams = {
    UnitIDs: unitIds.join(','),
    CustomerIDs: customerIds.join(','),
    Distance: distanceKm,
    UnitGroups: unitGroupIds.join(','),
    Rows: rows,
  };

  return getList<GetUnitsByPosResponse>(endpoints.GetUnitsByPos, params);
};

export const getUnitGroups = async ({
  customerId,
}: {
  customerId: number;
}): Promise<GetUnitGroupsResponse[]> => {
  const params: GetUnitGroupsParams = {
    CustomerID: customerId,
  };

  return getList<GetUnitGroupsResponse>(endpoints.GetUnitGroups, params, HTTPMethod.POST);
};

export const getUnitsInfo = async ({
  unitIds
}: {
  unitIds: number[]
}): Promise<GetUnitsInfoResponse[]> => {
  const params: GetUnitsInfoParams = {
    UnitIDs: unitIds.join(','),
    Rows: unitIds.length,
  };

  return getList<GetUnitsInfoResponse>(endpoints.GetUnitsInfo, params);
};

export const getUnitType = async ({
  customerIds = []
}: {
  customerIds: number[]
}): Promise<GetUnitTypeResponse[]> => {
  const params: GetUnitTypeParams = {
    CustomerIDs: customerIds.join(','),
  };

  return getList<GetUnitTypeResponse>(endpoints.GetUnitType, params);
};

export const getUnitsShowValue = async ({
  customerIds = [],
  unitIds = [],
}: {
  customerIds?: number[];
  unitIds?: number[];
}): Promise<GetUnitsShowValueResponse[]> => {
  const params: GetUnitsShowValueParams = {
    CustomerIDs: customerIds.join(','),
    UnitIDs: unitIds.join(','),
  };

  return getList<GetUnitsShowValueResponse>(endpoints.GetUnitsShowValue, params);
};

export const getUnitsData = async ({
  columns,
  lastValue,
  startDate = new Date('2010-01-01 00:00'.replace(/-/g, '/')), // LastValue without a Date doesn't work
  unitId,
}: {
  columns: string;
  lastValue?: boolean;
  startDate?: Date,
  unitId: number;
}): Promise<string> => {
  const params: GetUnitsDataParams = {
    Columns: columns,
    StartDate: formatDate(startDate),
    UnitID: unitId,
    ...(lastValue && { LastValue: true }),
  };

  return get<string>(endpoints.GetUnitsData, params);
};

export const getUnitsDataCSV = async ({
  columns,
  lastValue,
  startDate = new Date('2010-01-01 00:00'.replace(/-/g, '/')), // LastValue without a Date doesn't work
  unitId,
  stopDate
}: {
  columns: string;
  lastValue?: boolean;
  startDate?: Date,
  unitId: number;
  stopDate?: Date,
}): Promise<string> => {
  const params: GetUnitsDataCSVParams = {
    Columns: columns,
    StartDate: formatDate(startDate),
    UnitID: unitId,
    ...(stopDate && { StopDate: formatDate(stopDate) }),
    ...(lastValue && { LastValue: true }),
  };

  return get<string>(endpoints.GetUnitsDataCSV, params);
};

export const getRadarDates = async ({
  unitId,
  channelId,
  startDate,
  stopDate,
}: {
  unitId: number;
  channelId: number;
  startDate: Date
  stopDate?: Date
}): Promise<GetRadarDatesResponse[]> => {
  const params: GetRadarDatesParams = {
    StartDate: formatDate(startDate),
    ChannelID: channelId,
    UnitID: unitId,
    ...(stopDate && { StopDate: formatDate(stopDate) }),
  };

  return getList<GetRadarResponse>(endpoints.GetRadarDates, params);
};

export const getRadar = async ({
  channelId,
  customerId,
  radarDate,
  unitId,
}: {
  channelId: number;
  customerId: number;
  radarDate: Date
  unitId: number;
}): Promise<GetRadarResponse> => {
  const params: GetRadarParams = {
    ChannelID: channelId,
    CustomerID: customerId,
    RadarDate: formatDate(radarDate),
    UnitID: unitId,
  };

  return await getSingle<GetRadarResponse>(endpoints.GetRadar, params);
};

export const editMark = async ({
  unitId,
  customerId,
  channelId,
  markerId,
  markerData
}: {
  channelId: number;
  customerId: number;
  unitId: number;
  markerId: number
  markerData: unknown
}): Promise<ResultResponse> => {
  const params: EditMarkParams = {
    ChannelID: channelId,
    CustomerID: customerId,
    MarkerID: markerId,
    UnitID: unitId,
    JSonData: JSON.stringify(markerData)
  };

  const response = await post<ResultResponse[]>(endpoints.EditMark, params);

  return response[0];
};

export const deleteMark = async ({
  unitId,
  customerId,
  channelId,
  markerId
}: {
  channelId: number;
  customerId: number;
  unitId: number;
  markerId: number
}): Promise<ResultResponse> => {
  const params: DeleteMarkParams = {
    ChannelID: channelId,
    CustomerID: customerId,
    MarkerID: markerId,
    UnitID: unitId,
  };

  const response = await get<ResultResponse[]>(endpoints.DeleteMark, params);

  return response[0];
};
export const getAllMarks = async ({
  channelId,
  unitId,
}: {
  unitId: number;
  channelId: number;
}): Promise<GetAllMarksResponse> => {
  const params: GetAllMarksParams = {
    ChannelID: channelId,
    UnitID: unitId,
  };

  const response = await get<GetAllMarksResponse[]>(endpoints.GetAllMarks, params);

  if (isResult(response)) {
    return {
      ChannelID: channelId,
      Marker: [],
      UnitID: unitId,
    };
  }

  const data = response[0];

  if (isEmptyObject(data)) {
    throw new Error('Not found');
  }

  return data;
};

export const getKPIAlarms = async ({
  customerIds = [],
  unitIds = [],
}: {
  customerIds?: number[];
  unitIds?: number[];
}): Promise<GetKPIAlarmsResponse[]> => {
  const params: GetKPIAlarmsParams = {
    CustomerIDs: customerIds.join(','),
    UnitIDs: unitIds.join(','),
  };

  // Accumulate the number of alarms for each date
  const reducer: ResponseReducer<GetKPIAlarmsResponse> = (responses) => responses.reduce(
    (acc , current) => current.map((response) => {
      const found = acc.find(v => v.AlarmDate === response.AlarmDate);

      if (found) {
        return {
          AlarmDate: response.AlarmDate,
          Count: response.Count + found.Count,
        };
      } else {
        return response;
      }
    }, []));

  return getChunkedList<GetKPIAlarmsResponse, GetKPIAlarmsParams>(
    endpoints.GetKPIAlarms,
    params,
    unitIds,
    'UnitIDs',
    reducer,
  );
};

export const getKPIBattery = async ({
  customerIds = [],
  unitIds = [],
}: {
  customerIds?: number[];
  unitIds?: number[];
}): Promise<GetKPIBatteryResponse[]> => {
  const params: GetKPIBatteryParams = {
    CustomerIDs: customerIds.join(','),
    UnitIDs: unitIds.join(','),
  };
  // Accumulate the number of units for each battery level
  const reducer: ResponseReducer<GetKPIBatteryResponse> = (responses) => responses.reduce(
    (acc, current) => current.map((response) => {
      const found = acc.find(v => v.DataBatteryVoltage == response.DataBatteryVoltage && v.Status == response.Status);

      if (found) {
        const prevCount = found.D[0].NoOffUnits || 0;
        const currCount = response.D[0].NoOffUnits || 0;

        return {
          DataBatteryVoltage: response.DataBatteryVoltage,
          Status: response.Status,
          D: [ { NoOffUnits: prevCount + currCount } ],
        };
      } else {
        return response;
      }
    }), []);

  return getChunkedList<GetKPIBatteryResponse, GetKPIBatteryParams>(
    endpoints.GetKPIBattery,
    params,
    unitIds,
    'UnitIDs',
    reducer,
  );
};

export const getKPISwitch = async ({
  customerIds = [],
  unitIds = [],
}: {
  customerIds?: number[];
  unitIds?: number[];
}): Promise<GetKPISwitchResponse[]> => {
  const params: GetKPISwitchParams = {
    CustomerIDs: customerIds.join(','),
    UnitIDs: unitIds.join(','),
  };

  return getChunkedList<GetKPISwitchResponse, GetKPISwitchParams>(
    endpoints.GetKPISwitch,
    params,
    unitIds,
    'UnitIDs',
  );
};

export const getGISData = async ({
  customerId,
}: {
  customerId: number;
}): Promise<GetGISDataResponse> => {
  const params: GetGISDataParams = {
    CustomerID: customerId,
  };

  return get<GetGISDataResponse>(endpoints.GetGISData, params);
};

export const getReportParkingUsage = async ({
  startDate,
  startTime = '8:00',
  stopDate,
  stopTime = '17:00',
  unitIds,
}: {
  startDate: Date;
  startTime: string;
  stopDate: Date;
  stopTime: string;
  unitIds: number[];
}): Promise<GetReportParkingUsageResponse> => {
  const params: GetReportParkingUsageParams = {
    StartDate: formatDate(startDate),
    StartDay: startTime,
    StopDate: formatDate(stopDate),
    StopDay: stopTime,
    UnitIDs: unitIds.join(','),
  };

  return await getSingle<GetReportParkingUsageResponse>(endpoints.GetReportParkingUsage, params);
};

export const getWeather = async ({
  latitude,
  longitude,
  days = 5,
  lang = 'en'
}: {
  latitude: number;
  longitude: number;
  days?: number;
  lang?: string;
}): Promise<GetWeatherResponse> => {
  const params: GetWeatherParams = {
    lat: latitude,
    lon: longitude,
    days,
    lang
  };

  return get<GetWeatherResponse>(endpoints.GetWeather, params);
};

export const unitAction = async ({
  action,
  customerId,
  sequence,
  unitId,
  unitSerial,
}: {
  action: string;
  customerId: number;
  sequence: number;
  unitId: number;
  unitSerial: string;
}): Promise<ResultResponse> => {
  const params: UnitActionParams = {
    Action: action.toUpperCase(),
    CustomerID: customerId,
    RunAndWait: 1,
    SequenceNumber: sequence,
    UnitID: unitId,
    UnitSerial: unitSerial,
  };

  const data = await post<ResultResponse[]>(endpoints.UnitAction, params);

  if (!data.length) {
    throw new Error('No data returned from the API');
  }

  if (data.length > 1) {
    throw new Error(`Expected a single item, but ${data.length} was returned by the API`);
  }

  return data[0];
};

export const getUserData = async ({
  id,
  type,
  getDefault,
}: {
  id?: string,
  type?: string,
  getDefault?: boolean
}): Promise<GetUserDataResponse[]> => {
  const params: GetUserDataParams = {
    ...(id && { ValueID: id }),
    ...(type && { ValueType: type }),
    ...(getDefault && { Default: getDefault ? 'Y' : 'N' }),
  };

  return getList<GetUserDataResponse>(endpoints.GetUserData, params);
};

export const setUserData = async ({
  id,
  data,
  type,
  asDefault,
  serialize = true,
}: {
  id: string,
  data: unknown,
  type?: string,
  asDefault?: boolean,
  serialize?: boolean,
}): Promise<ResultResponse> => {
  const params: SetUserDataParams = {
    ValueID: id,
    JSonData: serialize ? JSON.stringify(data) : data,
    ...(type && { ValueType: type }),
    ...(asDefault && { Default: asDefault ? 'Y' : 'N' }),
  };

  const response = await post<ResultResponse[]>(endpoints.SetUserData, params);

  if (!response.length) {
    throw new Error('No data returned from the API');
  }

  if (response.length > 1) {
    throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
  }

  return response[0];
};

export const deleteUserData = async ({
  id,
  type,
}: {
  id: string,
  type?: string,
}): Promise<ResultResponse> => {
  const params: DeleteUserDataParams = {
    ValueID: id,
    ...(type && { ValueType: type }),
  };

  const response = await get<ResultResponse[]>(endpoints.DeleteUserData, params);

  if (!response.length) {
    throw new Error('No data returned from the API');
  }

  if (response.length > 1) {
    throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
  }

  return response[0];
};

export const getChannel = async ({
  channelId,
  unitId,
  customerId,
}: {
  unitId: number;
  channelId: number;
  customerId: number;
}): Promise<GetChannelResponse> => {
  const params: GetChannelParams = {
    ChannelID: channelId,
    UnitID: unitId,
    CustomerID: customerId
  };

  return await getSingle<GetChannelResponse>(endpoints.GetChannel, params);
};

export const editChannel = async ({
  unitId,
  customerId,
  channelId,
  columns,
}: {
  channelId: number;
  customerId: number;
  unitId: number;
  columns: string
}): Promise<ResultResponse> => {
  const params: EditChannelParams = {
    ChannelID: channelId,
    CustomerID: customerId,
    UnitID: unitId,
    Columns: columns
  };

  const response = await post<ResultResponse[]>(endpoints.EditChannel, params);

  return response[0];
};

export const setRelay = async ({
  unitId,
  dataValue,
}: {
  unitId: number,
  dataValue: Array<boolean>,
}): Promise<ResultResponse> => {
  const params: SetRelayParams = {
    UnitID: unitId,
    DataValue: dataValue.map(data => data ? '1' : '0').join(''),
  };

  const response = await get<ResultResponse[]>(endpoints.SetRelay, params);

  if (!response.length) {
    throw new Error('No data returned from the API');
  }

  if (response.length > 1) {
    throw new Error(`Expected a single item, but ${response.length} was returned by the API`);
  }

  return response[0];
};
