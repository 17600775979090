import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';

import store from '@/core/store';

Vue.use(VueRouter);

export const router: VueRouter = new VueRouter({
  routes: [
    {
      component: async (): Promise<typeof import('@/views/Login.vue')> =>
        import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      name: 'login',
      path: '/login',
    },
    {
      path: '/',
      name: 'root',
      redirect: () => {
        return { name: 'dashboard', params: { id: 'default' } };
      },
      component: async (): Promise<typeof import('@/views/Main.vue')> =>
        import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
      children: [
        {
          component: async (): Promise<typeof import('@/views/Dashboard.vue')> =>
            import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
          name: 'dashboard',
          path: '/dashboard/:id',
          beforeEnter: (to, from, next) => {
            if (to.params.id === 'default') {
              to.params.id = store.getters['dashboard/overviewId'];
              next();
            } else {
              next();
            }
          },
        },
        {
          component: async (): Promise<typeof import('@/views/Notifications.vue')> =>
            import(/* webpackChunkName: "notifications" */ '@/views/Notifications.vue'),
          name: 'notifications',
          path: '/notifications',
        },
        {
          component: async (): Promise<typeof import('@/views/DeviceManagement.vue')> =>
            import(/* webpackChunkName: "devicemanagement" */ '@/views/DeviceManagement.vue'),
          name: 'devicemanagement',
          path: '/devicemanagement',
        },
        {
          component: async (): Promise<typeof import('@/views/GroupManagement.vue')> =>
            import(/* webpackChunkName: "groupmanagement" */ '@/views/GroupManagement.vue'),
          name: 'groupmanagement',
          path: '/groupmanagement',
        },
        {
          component: async (): Promise<typeof import('@/views/LocationManagement.vue')> =>
            import(/* webpackChunkName: "locationmanagement" */ '@/views/LocationManagement.vue'),
          name: 'locationmanagement',
          path: '/locationmanagement',
        },
        {
          component: async (): Promise<typeof import('@/views/TaskManagement.vue')> =>
            import(/* webpackChunkName: "taskmanagement" */ '@/views/TaskManagement.vue'),
          name: 'taskmanagement',
          path: '/taskmanagement',
        },
        {
          component: async (): Promise<typeof import('@/views/Admin.vue')> =>
            import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
          name: 'admin',
          path: '/admin',
        },
        {
          component: async (): Promise<typeof import('@/views/Profile.vue')> =>
            import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
          name: 'profile',
          path: '/profile',
        },
      ],
    },
  ],
});

interface Next {
  name: string;
  query?: {
    redirect: string,
  }
}

export type RouteNext = (next?: Next) => void;

router.beforeEach(async (to: Route, from: Route, next: RouteNext) => {
  const hasLoaded = store.getters['dashboard/hasLoaded'];
  const user = store.getters['user/access'];

  if (to.name === 'login' && user) {
    next({ name: 'dashboard' });
  } else if (to.name === 'dashboard') {
    if (!user) {
      next( { name: 'login', query: { redirect: to.fullPath } });
    } else {
      if (!hasLoaded) {
        store.watch((state, getters) => getters['dashboard/hasLoaded'], () => {
          next();
        });
      } else {
        next();
      }
    }
  } else if (to.name !== 'login' && !user) {
    next({ name: 'login' });
  } else {
    next();
  }
});
