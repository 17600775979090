/**
 * @overview Event data model
 */

import { GetAlarmsResponse, GetUnitsInfoResponse } from '@wisionmonorepo/api-client-v1/src/responses';
import { nanoid } from 'nanoid';
import { DbEntity, DbRecord, EntityKind } from '@/core/models/dbEntity';
import { i18n } from '@/core/i18n';
import { criticalAlarmTypes } from '@wisionmonorepo/core-device-support/src/enums';
import { GetUnitsByPosResponse } from '@wisionmonorepo/api-client-v1/src/responses';

export enum EventType {
  System = 'system',
  Alarm = 'alarm',
}

export enum EventCategory {
  Notice = 'notice',
  Warning = 'warning',
  PreAlert = 'pre-alert',
  Alert = 'alert',
  Critical = 'critical',
}

export interface EventLocation {
  latitude: number;
  longitude: number;
}

export interface EventPosition {
  location?: EventLocation;
  address?: string;
}

export interface Event extends DbEntity {
  type: EventType;
  title: string;
  category: EventCategory;
}

export interface AlarmEvent extends Event {
  type: EventType.Alarm;
  position: EventPosition;
  unitId: number;
  unitName: string;
  unitType: number;
  alarmId: string;
  alarm: GetAlarmsResponse;
  groups?: Array<string>;
}

export interface SystemEvent extends Event {
  type: EventType.System;
}

export const modelVersion = '2';

/**
 * Create alarm event model from alarm API response
 */
export const createAlarmEvent = (
  alarm: GetAlarmsResponse,
  unit: GetUnitsInfoResponse | GetUnitsByPosResponse,
  groups?: Array<string>,
): AlarmEvent => {
  const id = nanoid();
  const type = EventType.Alarm;
  const alarmId = createAlarmId(alarm);
  const created = new Date();

  const alarmName = i18n.t(`alarmtypes.${alarm.AlarmType}`);
  const title = `${i18n.t('status.alarm')} ${alarmName}`;

  const category = criticalAlarmTypes.includes(alarm.AlarmType) ?
    EventCategory.Alert :
    EventCategory.Notice;

  const address = unit?.UnitLocation || undefined;
  const location: EventLocation | undefined = unit?.UnitGPSLongitude && unit?.UnitGPSLatitude ?
    { latitude: unit?.UnitGPSLatitude, longitude: unit?.UnitGPSLongitude } :
    undefined;

  const position: EventPosition = { location, address };

  return {
    id,
    created,
    version: modelVersion,
    kind: EntityKind.Event,
    type,
    title,
    position,
    category,
    unitId: unit.UnitID,
    unitName: unit.UnitName,
    unitType: unit.UnitType,
    alarmId,
    alarm,
    groups,
  };
};

/**
 * Migrate previous model versions to current
 */
export const migrateEventRecord = (record: DbRecord<Event>, version: string): DbRecord<Event> => {
  // Check if records exist that is not up to date
  if (!Object.values(record).map((event: Event) => event.version).find(v => v !== version)) {
    return record;
  }

  return Object.entries(record).reduce((acc: DbRecord<Event>, [id, event]: [ string, Event ]) => {
    if (event.version === '1') {
      return {
        ...acc,
        [id]: {
          ...event,
          version: '2',
          category: EventCategory.Notice, // Add needed category property
        }
      };
    } else {
      return {
        ...acc,
        [id]: event,
      };
    }
  }, {});
};

/**
 * Create alarm ID from alarm API response
 */
export const createAlarmId = (alarm: GetAlarmsResponse): string =>
  `alarm|${alarm.UnitID}|${alarm.ChannelID}|${alarm.AlarmType}|${alarm.AlarmEventTime}`;

/**
 * Validate event model
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateEvent = (event): event is Event => {
  return !!event &&
    event.kind === EntityKind.Event &&
    !!event.created &&
    !!event.id && typeof event.id === 'string' &&
    !!event.type && typeof event.type === 'string';
};

/**
 * Validate event database record
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateEventRecord = (data): data is DbRecord<Event> => {
  if (!data) return false;

  if (typeof data !== 'object') return false;

  const event = Object.values(data)?.[0];

  return validateEvent(event);
};
