import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import chunk from 'lodash/chunk';
import {
  EmptyObjectResponse,
  EmptyStringResponse,
  ListErrorResponse,
  ResultResponse,
} from './responses';
// import { debug } from '@wisionmonorepo/api-client-v1/../../../config.js';

export type ResponseReducer<Response> = (responses: Response[][]) => Response[];

export const MAX_REQUEST_ENTRIES = 4000;

export enum HTTPMethod {
  GET,
  POST,
}

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const formatDate = (timestamp: Date): string => moment(timestamp).format(DATE_FORMAT);

export const isResult = (obj: unknown): obj is [ResultResponse] =>
  Array.isArray(obj) && obj.length === 1 && isResultObject(obj[0]);

const isResultObject = (obj: unknown): obj is ResultResponse =>
  typeof obj === 'object' && obj !== null && 'Result' in obj;

const isEmpty = (obj: unknown): obj is [EmptyObjectResponse] =>
  Array.isArray(obj) && obj.length === 1 && isEmptyObject(obj[0]);

export const isEmptyObject = (obj: unknown): obj is EmptyObjectResponse =>
  typeof obj === 'object' && obj !== null && !Object.keys(obj).length;

export const get = async <Response>(endpoint: string, params: unknown): Promise<Response> => {
  // if (debug) console.info(`GET ${endpoint}: ${JSON.stringify(params)}`);

  let response: AxiosResponse<Response | EmptyStringResponse>;
  try {
    response = await axios.get<Response | EmptyStringResponse>('/api/' + endpoint, { params });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {    
    const message = `API [${endpoint}] ${err.message || err}`;
    throw new Error(message);
  }

  const data = response.data;

  // Handle common errors
  if (data === '') {
    const message = `API [${endpoint}] returned empty string`;
    throw new Error(message);
  }

  return data;
};

export const post = async <Response>(endpoint: string, args: unknown): Promise<Response> => {
  // if (debug) console.info(`POST ${endpoint}`); // Don't leak credentials

  let response: AxiosResponse<Response | EmptyStringResponse>;
  try {
    response = await axios.post<Response | EmptyStringResponse>('/api/' + endpoint, args);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    const message = `API [${endpoint}] ${err.message || err}`;
    throw new Error(message);
  }

  const data = response.data;

  // Handle common errors
  if (data === '') {
    const message = `API [${endpoint}] returned empty string`;
    throw new Error(message);
  }

  return data;
};

// Returns a list or an empty array
export const getList = async <Response>(
  endpoint: string,
  params: unknown,
  method: HTTPMethod = HTTPMethod.GET
): Promise<Response[]> => {
  const methodFunction = method === HTTPMethod.GET ? get : post;

  const data = await methodFunction<Response[] | ListErrorResponse>(endpoint, params);

  if (isEmpty(data)) {
    return [];
  }

  if (isResult(data)) {
    const message = `API [${endpoint}] error message - ${data[0].Result}`;
    throw new Error(message);
  }

  return data;
};

// Request array and divide the requests in chunks based on parameter ID values
export const getChunkedList = async <Response, Param>(
  endpoint: string,
  params: Param,
  ids: Array<number>,
  idKey: keyof Param,
  reducer: ResponseReducer<Response> = (responses) => responses.flat(1), // Default reducer flattens the array
  chunkSize: number = MAX_REQUEST_ENTRIES,
  method: HTTPMethod = HTTPMethod.POST,
): Promise<Response[]> => {

  if (!ids.length || ids.length < MAX_REQUEST_ENTRIES) {
    return getList<Response>(endpoint, params, method);
  }

  const chunks = chunk(ids, chunkSize);

  const promises: Array<Promise<Response[]>> = [];

  for (let i = 0; i < chunks.length; i++) {
    const chunkParams: Param = { ...params }; // Shallow copy
    // Pass the current chunked entries as a comma separated string for each iteration
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chunkParams[idKey] = <any> chunks[i].join(',');
    promises.push(getList<Response>(endpoint, chunkParams, method));
  }

  return Promise.all(promises)
    .then((collected) => reducer(collected));
};

// Returns a single object or throws an error
export const getSingle = async <Response>(
  endpoint: string,
  params: unknown,
  method: HTTPMethod = HTTPMethod.GET
): Promise<Response> => {
  const data = await getList<Response>(endpoint, params, method);

  if (!data.length) {
    const message = 'Not found';
    throw new Error(message);
  }

  if (data.length > 1) {
    const message = `Expected a single item, but ${data.length} was returned by the API`;
    throw new Error(message);
  }

  return data[0];
};
