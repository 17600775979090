/**
 * @overview User profile model
 */
import * as t from 'io-ts';

export const DEFAULT_TIMEZONE = 'GMT +01:00 Stockholm';
export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

export type UserRole = {
  id: number, 
  name: string, 
  organization_id: number 
}

export type UserProfile = {
  username: string;
  email: string;
  fullname?: string,
  title?: string;
  phone?: string;
  avatar?: string;
  gps_lat?: number;
  gps_long?: number;
  timezone?: string;
  notification_email?: number;
  notification_desktop?: number;
  time_12h?: number;
  date_format?: string;
  creation_date?: string;
  last_login?: string;
  last_wision_login?: string;
  language?: string;
  organizations?: { id: number, name: string, avatar?: string }[],
  organization_data?: string
  user_roles?: UserRole[],
  user_roles_data?: string
}

export const queries = {
  setLanguage: 'UPDATE user_profiles SET language = ? WHERE username = ?',
  selectOrganizationsByUsername: `
    SELECT 
      organizations.id,
      organizations.name,
      organizations.avatar
    FROM organizations
    JOIN user_roles ON organizations.id = user_roles.organization_id 
    WHERE user_roles.username = ?
  `,
  selectByUsername: 'SELECT * FROM user_profiles WHERE user_profiles.username = ? COLLATE NOCASE',
  selectUsersByUsernames: (usernames: string[]) => {
    const placeholders = usernames.map(() => '?').join(',');

    return `SELECT * FROM user_profiles WHERE user_profiles.username IN (${placeholders})`;
  },
  selectByOrganizations: 'SELECT * FROM user_profiles WHERE user_profiles.username = ? COLLATE NOCASE',

  selectByEmail:  'SELECT * FROM user_profiles WHERE user_profiles.email = ?',

  selectUserRolesByUsername: `
     SELECT role_id as id, organization_id, roles.description as name
     FROM user_roles
     JOIN roles ON role_id = roles.id
     WHERE username = ?;
  `,

  updateLastLogin:  'UPDATE user_profiles SET last_wision_login = datetime(\'now\') WHERE username = ?',

  update: `UPDATE user_profiles
   SET email = COALESCE(?, email),
   fullname = COALESCE(?, fullname),
   title = COALESCE(?, title),
   phone = COALESCE(?, phone),
   avatar = COALESCE(?, avatar),
   gps_lat = COALESCE(?, gps_lat),
   gps_long = COALESCE(?, gps_long),
   timezone = COALESCE(?, timezone),
   notification_email = COALESCE(?, notification_email),
   notification_desktop = COALESCE(?, notification_desktop),
   time_12h = COALESCE(?, time_12h),
   date_format = COALESCE(?, date_format),
   language = COALESCE(?, language)
  WHERE username = ?;`,

  insert: `INSERT INTO user_profiles (username, email, fullname, title, phone, avatar, gps_lat, gps_long, timezone, notification_email, notification_desktop, time_12h, date_format)
  VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);`,

  insertOrIgnore: `INSERT OR IGNORE INTO user_profiles (username, email, fullname)
  VALUES (?, ?, ?) RETURNING username;`,

  deleteUserProfileFromOrganization: 'DELETE FROM user_roles WHERE organization_id = ? AND username = ?'
};

export interface CreatePasswordTokenRequest {
  userId: string,
  baseUrl: string
}

export const userRequestCodec = t.type({
  username: t.string,
  password: t.string,
  email: t.string,
  name: t.string,
  phone: t.string,
  organizations: t.array(t.number),
});

export type UserRequest = t.TypeOf<typeof userRequestCodec>;
