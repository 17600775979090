import { UnitStatus, UnitStatusNames, AlarmTypes } from './enums';
import { AlarmEvent, GetAlarmsResponse, GetUnitsByPosResponse } from '@wisionmonorepo/api-client-v1/src/responses';

type GetAlarmStatus = (alarm: GetAlarmsResponse) => UnitStatusNames;
type GetUnitStatus = (unit: GetUnitsByPosResponse) => UnitStatus;
type GetUnitStatusName = (unit: GetUnitsByPosResponse) => UnitStatusNames;
type GetSelectedStatus = (status: UnitStatusNames) => UnitStatus;

const INACTIVE_INDICATOR = 'Ej Installerad';

export const getAlarmStatus: GetAlarmStatus = (alarm: GetAlarmsResponse): UnitStatusNames => {
  if (alarm.AlarmType === AlarmTypes.COMMUNICATION_ERROR) {
    return UnitStatusNames.Problem;
  }

  return UnitStatusNames.Alarm;
};

export const getUnitStatus: GetUnitStatus = (unit: GetUnitsByPosResponse): UnitStatus => {
  const isAlarmEvent = (event: AlarmEvent) => typeof event.AlarmType === 'number' && event.AlarmType >= 0;

  const result: UnitStatus = {
    alarm: false,
    inactive: false,
    ok: false,
    problem: false,
  };

  result.inactive = unit
    .UnitName
    .indexOf(INACTIVE_INDICATOR) > -1;

  result.problem = !result.inactive && unit
    .AlarmEvents
    .filter((alarmEvent) =>
      alarmEvent.AlarmType === AlarmTypes.COMMUNICATION_ERROR)
    .length > 0;

  result.alarm = !result.inactive && !result.problem && unit
    .AlarmEvents
    .filter(isAlarmEvent)
    .length > 0;

  result.ok = !result.problem && !result.alarm && !result.inactive;

  return result;
};

export const getUnitStatusName: GetUnitStatusName = (unit: GetUnitsByPosResponse): UnitStatusNames => {
  let result: UnitStatusNames = UnitStatusNames.Ok;
  const status: UnitStatus = getUnitStatus(unit);
  if (status.alarm) {
    result = UnitStatusNames.Alarm;
  } else if (status.problem) {
    result = UnitStatusNames.Problem;
  } else if (status.inactive) {
    result = UnitStatusNames.Inactive;
  }

  return result;
};

export const getSelectedStatus: GetSelectedStatus = (status: UnitStatusNames): UnitStatus => ({
  alarm: status === UnitStatusNames.Alarm,
  inactive: status === UnitStatusNames.Inactive,
  ok: status === UnitStatusNames.Ok,
  problem: status === UnitStatusNames.Problem,
});
