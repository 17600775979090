/**
 * @overview Vuex module for event management. Handles events, notifications and tasks.
 */

import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/store/types';
import { DbEntity, DbRecord, EntityKind } from '@/core/models/dbEntity';
import {
  AlarmEvent,
  createAlarmEvent,
  createAlarmId,
  Event,
  EventType,
  modelVersion as eventModelVersion,
  migrateEventRecord,
  validateEventRecord,
} from '@/core/models/event';
import {
  createAlarmNotification,
  markNotificationAsSeen,
  Notification,
  NotificationType,
  validateNotificationRecord,
} from '@/core/models/notification';
import { ActionTypes as DeviceActions } from '@/core/store/modules/device';
import {
  Activity,
  addTaskEvents,
  createAlarmSignature,
  createAlarmTask,
  Task,
  validateTaskRecord,
} from '@/core/models/task';
import Vue from 'vue';
import { GetAlarmsResponse, GetUnitsInfoResponse } from '@wisionmonorepo/api-client-v1/src/responses';
import apiAdapter from '@/lib/keyv-userapi';
import Keyv from '@keyvhq/core';
import { debug } from '@/../../../../config.js';
import { getAlarms } from '@wisionmonorepo/api-client-v1/src/requests';
import Timeout = NodeJS.Timeout;

type ModuleModel = Notification | Event | Task;

type ModuleKind = ModuleModel['kind'];

type ModelValidator<T> = (data: unknown) => data is T;
type ModelMigrator = (record: DbRecord<ModuleModel>, version: string) => DbRecord<ModuleModel>;

export interface EventState {
  records: Record<ModuleKind, DbRecord<ModuleModel>>;
  recordsLoaded: boolean;
  alarmIdIndex: Record<string, string>;
  alarmListenerTimer?: Timeout;
}

type Context = ActionContext<EventState, RootState>;

export enum MutationTypes {
  CLEAR_STATE = 'CLEAR_STATE',
  SET_RECORD = 'SET_RECORD',
  DELETE_RECORD = 'DELETE_RECORD',
  SET_DB_RECORD = 'SET_DB_RECORD',
  SET_RECORDS_LOADED = 'SET_RECORDS_LOADED',
  SET_ALARM_INDEX = 'SET_ALARM_INDEX',
  SET_ALARM_INDEXES = 'SET_ALARM_INDEXES',
  SET_ALARM_TIMER = 'SET_ALARM_TIMER',
}

export enum ActionTypes {
  FETCH_DB_RECORD = 'FETCH_DB_RECORD',
  SAVE_DB_RECORD = 'SAVE_DB_RECORD',
  SAVE_ALL = 'SAVE_ALL',
  FETCH_ALL = 'FETCH_ALL',
  CALCULATE_ALARM_INDEXES = 'CALCULATE_ALARM_INDEXES',
  IMPORT_ALARMS = 'IMPORT_ALARMS',
  MARK_NOTIFICATION_SEEN = 'MARK_NOTIFICATIONS_SEEN',
  ENABLE_ALARM_LISTENER = 'ENABLE_ALARM_LISTENER',
  DISABLE_ALARM_LISTENER = 'DISABLE_ALARM_LISTENER',
  DELETE_TASK = 'DELETE_TASK',
  UPDATE_TASK = 'UPDATE_TASK'
}

const getDefaultState = (): EventState => ({
  records: {
    [EntityKind.Event]: {},
    [EntityKind.Notification]: {},
    [EntityKind.Task]: {},
  },
  recordsLoaded: false,
  alarmIdIndex: {},
  alarmListenerTimer: undefined,
});

const state = getDefaultState();

const KV_NAMESPACE = '_event_management';

const CHECK_ALARMS_INTERVAL = 1000 * 60 * 30; // Once every thirty minutes

const KV_KEYS: { [K in ModuleKind ]: string } = {
  [EntityKind.Event]: 'events',
  [EntityKind.Notification]: 'notifications',
  [EntityKind.Task]: 'tasks',
};

const kvAdapter = new apiAdapter({ namespace: KV_NAMESPACE });
const kv = new Keyv({ store: kvAdapter });

const mutations = <MutationTree<EventState>>{
  /**
   * Clear all module state
   */
  [MutationTypes.CLEAR_STATE] (state: EventState) {
    Object.assign(state, getDefaultState());
  },
  /**
   * Set single record
   */
  [MutationTypes.SET_RECORD]: (state: EventState, { model, data }: { model: ModuleKind, data: DbEntity}) => {
    Vue.set(state.records[model], data.id, { ...data });
  },

  /**
   * Delete single record
   */
  [MutationTypes.DELETE_RECORD]: (state: EventState, { model, id }: { model: ModuleKind, id: string}) => {
    Vue.delete(state.records[model], id);
  },

  /**
   * Set records loaded
   */
  [MutationTypes.SET_RECORDS_LOADED]: (state: EventState) => {
    Vue.set(state, 'recordsLoaded', true);
  },

  /**
   * Set complete DB record
   */
  [MutationTypes.SET_DB_RECORD]: <T extends ModuleModel>(
    state: EventState,
    { model, data }: { model: ModuleKind, data: DbRecord<T>}
  ) => {
    Vue.set(state.records, model, { ...data });
  },

  /**
   * Set alarm id indexes
   */
  [MutationTypes.SET_ALARM_INDEXES] (state: EventState, idIndex: Record<string, string>) {
    state.alarmIdIndex = { ...idIndex };
  },

  /**
   * Set individual alarm id index
   */
  [MutationTypes.SET_ALARM_INDEX] (state: EventState, { alarmId, eventId }: { alarmId: string, eventId: string}) {
    state.alarmIdIndex[alarmId] = eventId;
  },

  /**
   * Set alarm timer
   */
  [MutationTypes.SET_ALARM_TIMER] (state: EventState, timer: Timeout | undefined ){
    state.alarmListenerTimer = timer;
  },
};

const actions = <ActionTree<EventState, RootState>>{
  /**
   * Save all
   */
  async [ActionTypes.SAVE_ALL] (context: Context) {
    return Promise.all([
      context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Notification }),
      context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Event }),
      context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task }),
    ]);
  },

  /**
   * Fetch all
   */
  async [ActionTypes.FETCH_ALL] (context: Context) {
    try {
      await Promise.all([
        context.dispatch(
          ActionTypes.FETCH_DB_RECORD,
          { model: EntityKind.Notification, validate: validateNotificationRecord }
        ),

        context.dispatch(
          ActionTypes.FETCH_DB_RECORD,
          {
            model: EntityKind.Event,
            validate: validateEventRecord,
            migrate: migrateEventRecord,
            version: eventModelVersion
          }),

        context.dispatch(
          ActionTypes.FETCH_DB_RECORD,
          { model: EntityKind.Task, validate: validateTaskRecord }
        ),
      ]);

      context.commit(MutationTypes.SET_RECORDS_LOADED);

      return context.dispatch(ActionTypes.CALCULATE_ALARM_INDEXES);
    } catch (error) {
      console.info(`Error fetching event management data: ${error}`);
    }
  },

  /**
   * Fetch db record from permanent storage
   */
  [ActionTypes.FETCH_DB_RECORD]: async <T extends ModuleModel>(
    context: Context, {
      model,
      validate,
      migrate,
      version
    }: {
      model: ModuleKind,
      validate: ModelValidator<T>,
      migrate?: ModelMigrator,
      version?: string
    }) => {
    const record = await kv.get(KV_KEYS[model]) as unknown as DbRecord<T>;

    if (validate(record)) {
      const currentRecord = migrate && version ? migrate(record, version) : record;
      context.commit(MutationTypes.SET_DB_RECORD, { model, data: currentRecord });
    } else if (debug) console.info('Invalid db record for', model);
  },

  /**
   * Save DB record to permanent storage
   */
  [ActionTypes.SAVE_DB_RECORD]: async (context: Context, { model } : { model: ModuleKind }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return kv.set(KV_KEYS[model], context.state.records[model] as any);
  },

  /**
   * Calculate and store alarm indexes
   */
  async [ActionTypes.CALCULATE_ALARM_INDEXES] (context: Context) {
    const values = Object.values(context.state.records[EntityKind.Event]) as Array<Event>;

    const indexes = Object.fromEntries(
      values
        .filter(event => event.type === EventType.Alarm)
        .map(event => {
          const alarmEvent = event as AlarmEvent;
          return [alarmEvent.alarmId, event.id];
        }));

    context.commit(MutationTypes.SET_ALARM_INDEXES, indexes);
  },

  /**
   * Mark notification as seen
   */
  async [ActionTypes.MARK_NOTIFICATION_SEEN] (context: Context, id: string) {
    const notification = context.state.records[EntityKind.Notification][id] as Notification;

    if (!notification) {
      if (debug) console.info('Notification not found:', id);
      return;
    }

    context.commit(
      MutationTypes.SET_RECORD,
      { model: EntityKind.Notification, data: markNotificationAsSeen(notification) }
    );
  },

  /**
   * Insert new alarms
   */
  async [ActionTypes.IMPORT_ALARMS] ({ state, commit, dispatch, getters, rootGetters }, alarms: GetAlarmsResponse[]) {
    if (!alarms || !state.recordsLoaded) return;

    const newAlarms = alarms.filter(alarm => !state.alarmIdIndex[createAlarmId(alarm)]);

    if (!newAlarms.length) return;
    await dispatch(`device/${DeviceActions.CACHE_DEVICE_DETAILS}`, newAlarms.map(alarm => alarm.UnitID), { root: true });

    for (const alarm of newAlarms) {
      const id = createAlarmId(alarm);

      if (debug) console.info('New alarm:', id);

      const unit: GetUnitsInfoResponse = await dispatch(`device/${DeviceActions.FETCH_DEVICE_DETAILS}`, alarm.UnitID, { root: true });
      const groups = rootGetters['container/unitGroupNames'](unit.UnitID);
      const event = createAlarmEvent(alarm, unit, groups.length ? groups : undefined);
      const notification = createAlarmNotification(event);

      commit(MutationTypes.SET_RECORD, { model: EntityKind.Notification, data: notification });

      commit(MutationTypes.SET_RECORD, { model: EntityKind.Event, data: event });

      commit(MutationTypes.SET_ALARM_INDEX, { alarmId: event.alarmId, eventId: event.id });

      // Lookup existing alarm signature in active tasks
      const signature = createAlarmSignature(alarm);

      const existingTask = getters.tasksActive
        .find((task) => task.signature === signature);

      if (existingTask) { // Add event id to existing task if found
        const task = addTaskEvents(existingTask, [ event.id ]);
        commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
      } else { // Create new task
        const task = createAlarmTask({ events: [ event ] });
        commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
      }
    }

    dispatch(ActionTypes.SAVE_ALL);
  },

  /**
   * Enable periodic listening for alarms
   */
  async [ActionTypes.ENABLE_ALARM_LISTENER] ({ commit, state, dispatch, rootGetters }, immediate = false) {
    const alarmsHandler = async () => {
      if (!state.recordsLoaded) {
        if (debug) console.info('Data records not initialized');
        return;
      }

      const customerIds = rootGetters['user/selectedCustomerIds'];

      if (!customerIds?.length) return;

      const alarms = await getAlarms({
        customerIds,
      });

      dispatch(ActionTypes.IMPORT_ALARMS, alarms);
    };

    if (immediate) alarmsHandler();

    const timer = setInterval(alarmsHandler, CHECK_ALARMS_INTERVAL);

    commit(MutationTypes.SET_ALARM_TIMER, timer);
  },

  /**
   * Disable periodic listening for alarms
   */
  async [ActionTypes.DISABLE_ALARM_LISTENER] ({ state, commit }) {
    if (state.alarmListenerTimer) {
      clearInterval(state.alarmListenerTimer);
      commit(MutationTypes.SET_ALARM_TIMER, undefined);
    }
  },

  /**
   * Delete task
   */
  [ActionTypes.DELETE_TASK]: async (context: Context, id: string) => {
    context.commit(MutationTypes.DELETE_RECORD, { model: EntityKind.Task, id });
    context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task });
  },

  /**
   * Update task
   */
  [ActionTypes.UPDATE_TASK]: async (context: Context, task: Task) => {
    if (task) {
      context.commit(MutationTypes.SET_RECORD, { model: EntityKind.Task, data: task });
      context.dispatch(ActionTypes.SAVE_DB_RECORD, { model: EntityKind.Task });
    } else if (debug) console.info('Task update rejected because empty');
  },
};

const getters = <GetterTree<EventState, RootState>> {
  /**
   * Indicate if records has loaded
   */
  recordsLoaded(state: EventState): boolean  {
    return state.recordsLoaded;
  },

  /**
   * Get events by ids
   */
  eventsByIds(state: EventState): (eventIds: string[]) => Event[] {
    return (eventIds: string[]) => eventIds.map((id) => state.records[EntityKind.Event][id]) as Event[];
  },

  /**
   * Get event by id
   */
  eventById(state: EventState) {
    return (id: string): Event => {
      return state.records[EntityKind.Event][id] as Event;
    };
  },

  /**
   * Get the notifications record sorted by date
   */
  notifications(state: EventState): Notification[] {
    const notifications = Object.values(state.records[EntityKind.Notification]) as Notification[];

    return notifications.sort((a, b) => {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    });
  },

  /**
   * Get a single notification
   */
  notificationById(state: EventState) {
    return (id: string): Notification => {
      return state.records[EntityKind.Notification][id] as Notification;
    };
  },

  /**
   * Get the count of all notifications
   */
  notificationCount(state: EventState): number {
    return Object.values(state.records[EntityKind.Notification]).length;
  },

  /**
   * Get the count of alarms
   */
  notificationAlarmCount(state: EventState): number {
    const notifications = Object.values(state.records[EntityKind.Notification]) as Notification[];

    return notifications
      .filter((notification) => notification.type === NotificationType.Alarm)
      .length;
  },

  /**
   * Get the count of unseen notifications
   */
  notificationUnseenCount(state: EventState): number {
    const notifications = Object.values(state.records[EntityKind.Notification]) as Notification[];

    return notifications
      .filter((notification) => !notification.seen)
      .length;
  },

  /**
   * Get tasks sorted by date
   */
  tasks(state: EventState): Task[] {
    const tasks = Object.values(state.records[EntityKind.Task]) as Task[];

    return tasks
      .sort((a, b) => (new Date(b.created).getTime() - new Date(a.created).getTime()));
  },

  /**
   * Get a single task
   */
  taskById(state: EventState) {
    return (id: string): Task => {
      return state.records[EntityKind.Task][id] as Task;
    };
  },

  /**
   * Get active tasks sorted by date
   */
  tasksActive(state: EventState): Task[] {
    const tasks = Object.values(state.records[EntityKind.Task]) as Task[];

    return tasks
      .sort((a, b) => (new Date(b.created).getTime() - new Date(a.created).getTime()));
  },

  /**
   * Get a task notes
   */
  taskNotes(state: EventState) {
    return (id: string): Array<Activity> => {
      return (state.records[EntityKind.Task][id] as Task).activities
        .filter(activity => activity.type === 'note');
    };
  },

  /**
   * Get a tasks latest event
   */
  taskLatestEvent(state: EventState) {
    return (id: string): Event => {
      const task = state.records[EntityKind.Task][id] as Task;
      const lastEventId = task.eventIds[task.eventIds.length - 1];
      return state.records[EntityKind.Event][lastEventId] as Event;
    };
  },
};

export default <Module<EventState, RootState>>{
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
