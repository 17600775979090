/**
 * @overview Task data model
 */

import { nanoid } from 'nanoid';
import { DbEntity, DbRecord, EntityKind } from '@/core/models/dbEntity';
import { AlarmEvent, EventCategory } from '@/core/models/event';
import { GetAlarmsResponse } from '@wisionmonorepo/api-client-v1/src/responses';

export const DEFAULT_TASK_NAME = 'New task';
export const DEFAULT_TASK_SIGNATURE = 'default';

const modelVersion = '2';

export enum TaskStatus {
  Resting = 'resting',
  Working = 'working',
  Reported = 'reported',
  Done = 'done',
}

export enum TaskType {
  Custom = 'custom',
  Alarm = 'alarm',
}

export enum ActivityType {
  Status = 'status',
  Note = 'note',
  Assignees = 'assignees',
  Events = 'events',
  Title = 'title',
  Category = 'category'
}

export interface Activity {
  date: Date;
  type: ActivityType;
  user: string;
}

export interface StatusActivity extends Activity {
  type: ActivityType.Status;
  status: TaskStatus;
}

export interface NoteActivity extends Activity {
  type: ActivityType.Note;
  note: string;
}

export interface CategoryActivity extends Activity {
  type: ActivityType.Category;
  category: string;
}

export interface AssigneesActivity extends Activity {
  type: ActivityType.Assignees;
  assignees: Array<string>;
}

export interface EventsActivity extends Activity {
  type: ActivityType.Events;
  eventIds: Array<string>;
}

export interface Task extends DbEntity {
  type: TaskType,
  title?: string;
  category?: EventCategory;
  signature: string;
  eventIds: Array<string>;
  assignees: Array<string>;
  status: TaskStatus;
  activities: Array<Activity>;
}

const SYSTEM_USER = 'system';

/**
 * Create activity model
 */
export const createActivity = ({
  type,
  note,
  user,
  status,
  title,
  category,
  assignees,
  eventIds,
} : {
  type: ActivityType,
  user?: string,
  note?: string,
  category?: EventCategory,
  status?: TaskStatus,
  title?: string,
  assignees?: Array<string>,
  eventIds?: Array<string>
}): Activity => {
  const activity = {
    date: new Date(),
    type,
  };

  activity['user'] = user || SYSTEM_USER;

  switch (type) {
  case ActivityType.Note: {
    if (!note) throw new Error('Missing note parameter');

    activity['note'] = note;

    break;
  }
  case ActivityType.Status: {
    if (!status) throw new Error('Missing status parameter');

    activity['status'] = status;

    break;
  }
  case ActivityType.Assignees: {
    if (!assignees) throw new Error('Missing assignees parameter');

    activity['assignees'] = assignees;

    break;
  }
  case ActivityType.Events: {
    if (!eventIds) throw new Error('Missing events parameter');

    activity['eventIds'] = eventIds;

    break;
  }
  case ActivityType.Category: {
    if (!category) throw new Error('Missing category parameter');

    activity['category'] = category;

    break;
  }
  case ActivityType.Title: {
    if (!title) throw new Error('Missing title parameter');

    activity['title'] = title;

    break;
  }
  }

  return activity as Activity;
};

/**
 * Create task model
 */
export const createTask = ({
  type = TaskType.Custom,
  title = undefined,
  category = undefined,
  signature = DEFAULT_TASK_SIGNATURE,
  eventIds = [],
} : {
  title?: string,
  category?: EventCategory,
  type?: TaskType,
  signature?: string,
  eventIds?: string[],
}): Task => {
  const id = nanoid();
  const created = new Date();
  const assignees = [];
  const status = TaskStatus.Resting;

  const activities = [
    createActivity({ type: ActivityType.Status, status })
  ];

  return {
    id,
    created,
    version: modelVersion,
    kind: EntityKind.Task,
    title,
    category,
    type,
    signature,
    eventIds,
    assignees,
    status,
    activities,
  };
};

/**
 * Assign user to task
 */
export const assignUserToTask = (task: Task, user: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  const assignees = [ ...task.assignees, user ];

  return {
    ...task,
    assignees,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Assignees, assignees, user })],
  };
};

/**
 * Withdraw user from task
 */
export const withdrawUserFromTask = (task: Task, user: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  const index = task.assignees.indexOf(user);

  if (index > -1) {
    const assignees = task.assignees.slice(0, index).concat(task.assignees.slice(index + 1));

    return {
      ...task,
      assignees,
      activities: [ ...task.activities, createActivity({ type: ActivityType.Assignees, assignees, user })],
    };
  } else {
    throw new Error('User not assigned');
  }
};

/**
 * Update task note
 */
export const updateTaskNote = (task: Task, note: string, user?: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return {
    ...task,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Note, note, user })],
  };
};

/**
 * Update task category
 */
export const updateTaskCategory = (task: Task, category: EventCategory, user?: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return {
    ...task,
    category,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Category, category, user })],
  };
};

/**
 * Update task status
 */
export const updateTaskStatus = (task: Task, status: TaskStatus, user?: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return {
    ...task,
    status,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Status, status, user })],
  };
};

/**
 * Update task status
 */
export const updateTaskTitle = (task: Task, title: string, user?: string): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return {
    ...task,
    title,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Title, title, user })],
  };
};

/**
 * Update associated task events
 */
export const updateTaskEvents = (task: Task, eventIds: Array<string>): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return {
    ...task,
    eventIds,
    activities: [ ...task.activities, createActivity({ type: ActivityType.Events, eventIds })],
  };
};

/**
 * Add task events
 */
export const addTaskEvents = (task: Task, eventIds: Array<string>): Task => {
  if (!task) throw new Error('Invalid task parameter');

  return updateTaskEvents(task, task.eventIds.concat(eventIds));
};

/**
 * Create signature for alarm task
 */
export const createAlarmSignature = (alarm: GetAlarmsResponse): string => {
  return `Alarm|${alarm.UnitID}`;
};

/**
 * Lookup referenced unit in alarm task
 */
export const alarmTaskUnit = (task: Task): number | undefined => {
  if (task.type !== TaskType.Alarm) return undefined;

  return Number(task.signature.match(/\d+$/g)?.join(''));
};

/**
 * Create alarm task from alarm API response
 */
export const createAlarmTask = ({
  events,
} : { events: Array<AlarmEvent> }): Task => {
  const alarm = events[0].alarm;

  const eventIds = events.map(event => event.id);
  const signature = createAlarmSignature(alarm);

  return createTask({ eventIds, type: TaskType.Alarm, signature });
};

/**
 * Validate task model
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateTask = (task): task is Task => {
  return !!task &&
    task.kind === EntityKind.Task &&
    !!task.created &&
    !!task.id && typeof task.id === 'string' &&
    !!task.type && typeof task.type === 'string' &&
    !!task.status && typeof task.status === 'string' &&
    !!task.activities && typeof task.activities === 'object';
};

/**
 * Validate task database record
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const validateTaskRecord = (data): data is DbRecord<Task> => {
  if (!data) return false;

  if (typeof data !== 'object') return false;

  const task = Object.values(data)?.[0] as Task;

  return validateTask(task);
};
