/**
 * @overview Vuex module that create an interface to state of containers
 */
import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/store/types';
import { importGroup } from '@/lib/import-group';
import Keyv from '@keyvhq/core';
import apiAdapter from '@/lib/keyv-userapi';
import { debug } from '@/../../../../config.js';
import {
  Container,
  ContainerRecord,
  Reference,
  createContainer,
  insertReference,
  deleteReference, deleteReferences
} from '@/core/models/container';
import { getErrorMessage } from '@/lib/helpers';

type Context = ActionContext<ContainerState, RootState>;

export enum TypeIdent {
  GROUPS = 'groups',
  LOCATIONS = 'locations',
}

export interface ContainerState {
  containers: Record<TypeIdent, ContainerRecord>;
  hasLoaded: boolean,
}

export enum MutationTypes {
  SET_RECORD = 'SET',
  MERGE = 'MERGE',
  INSERT = 'INSERT',
  DELETE = 'DELETE',
  SET_LOADED = 'SET_LOADED',
  CLEAR_STATE = 'CLEAR_STATE',
}

export enum ActionTypes {
  FETCH = 'FETCH',
  SAVE = 'SAVE',
  MIGRATE = 'MIGRATE',
  INSERT_REFERENCE = 'INSERT_REFERENCE',
  DELETE_REFERENCE = 'DELETE_REFERENCE',
  DELETE_REFERENCES = 'DELETE_REFERENCES',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DEBUG = 'DEBUG',
  RESET = 'RESET',
}

const KV_NAMESPACE = '_group';
const GROUPS_IDENT = 'groups';
const LOCATIONS_IDENT = 'locations';

export const getDefaultState = (): ContainerState => ({
  containers: {
    groups: {},
    locations: {},
  },
  hasLoaded: false,
});

const state = getDefaultState();

const kvAdapter = new apiAdapter({ namespace: KV_NAMESPACE });
const kv = new Keyv({ store: kvAdapter });

/** Validate containers record **/
const isContainerStorage = (data): data is ContainerRecord => {
  const container = Object.values(data)?.[0] as Container;

  return !!container && !!container.name &&
    !!container.id &&
    !!container.content && typeof container.content == 'object';
};

const mutations = <MutationTree<ContainerState>>{
  /** Set that the containers has been loaded **/
  [MutationTypes.SET_LOADED] (state: ContainerState) {
    state.hasLoaded = true;
  },

  /** Insert a full container record in to state record **/
  [MutationTypes.SET_RECORD] (state: ContainerState, {
    typeId,
    record,
  } : {
    typeId: TypeIdent,
    record: ContainerRecord,
  }) {
    Vue.set(state.containers, typeId, record);
  },

  /** Merge a containers record object into a particular state record **/
  [MutationTypes.MERGE] (state: ContainerState, {
    typeId,
    containers
  } : {
    typeId: TypeIdent,
    containers: ContainerRecord
  }) {
    Object.entries(containers).forEach(([id, container]) => {
      Vue.set(state.containers[typeId], id, container);
    });
  },

  /** Insert single container into the a particular state record **/
  [MutationTypes.INSERT] (state: ContainerState, {
    typeId,
    container
  } : {
    typeId: TypeIdent,
    container: Container
  }) {
    Vue.set(state.containers[typeId], container.id, container);
  },

  /** Delete a container from the state record **/
  [MutationTypes.DELETE] (state: ContainerState, { typeId, id } : { typeId: TypeIdent, id: string }) {
    Vue.delete(state.containers[typeId], id);
  },

  /** Clear container state **/
  [MutationTypes.CLEAR_STATE] (state: ContainerState) {
    Object.assign(state, getDefaultState());
  },
};

const actions = <ActionTree<ContainerState, RootState>>{
  /** Fetch saved groups and locations from permanent storage **/
  async [ActionTypes.FETCH] (context: Context) {

    /** Clearing state before fetching new data **/
    context.state.containers[TypeIdent.GROUPS] = {};
    context.state.containers[TypeIdent.LOCATIONS] = {};

    const groups = await kv.get(GROUPS_IDENT) as unknown as ContainerRecord;
    if (groups && isContainerStorage(groups)) {
      if (debug) console.info(`Loaded ${Object.keys(groups).length} groups`);
      context.commit(MutationTypes.MERGE, { typeId: TypeIdent.GROUPS, containers: groups });
    } else if (!groups) {
      context.state.containers[TypeIdent.GROUPS] = {};
    } else if (debug) {
      console.info('No valid user groups found');
    }

    const locations = await kv.get(LOCATIONS_IDENT) as unknown as ContainerRecord;
    if (locations && isContainerStorage(locations)) {
      if (debug) console.info(`Loaded ${Object.keys(locations).length} locations`);
      context.commit(MutationTypes.MERGE, { typeId: TypeIdent.LOCATIONS, containers: locations });
    } else if (!locations) {
      context.state.containers[TypeIdent.LOCATIONS] = {};
    } else if (debug) {
      console.info('No valid user groups found');
    }

    context.commit(MutationTypes.SET_LOADED);
  },

  /** Clear container state **/
  async [ActionTypes.RESET] (context: Context) {
    context.commit(MutationTypes.CLEAR_STATE);
  },

  /** Save a containers record to permanent storage **/
  async [ActionTypes.SAVE] (context: Context, typeId: TypeIdent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return kv.set(typeId, context.state.containers[typeId] as any);
  },

  /** Migrate a customer into a group and multiple locations **/
  async [ActionTypes.MIGRATE] (context: Context, { customerId, name } : { customerId: number, name: string }) {
    try {
      const [groups, locations] = await importGroup(customerId, name);

      if (groups && isContainerStorage(groups)) {
        context.commit(MutationTypes.MERGE, { typeId: TypeIdent.GROUPS, containers: groups });
        await context.dispatch(ActionTypes.SAVE, TypeIdent.GROUPS);
      } else if (debug) {
        console.info('No valid groups data found');
      }

      if (locations && isContainerStorage(locations)) {
        context.commit(MutationTypes.MERGE, { typeId: TypeIdent.LOCATIONS, containers: locations });
        await context.dispatch(ActionTypes.SAVE, TypeIdent.LOCATIONS);
      } else if (debug) {
        console.info('No valid locations data found');
      }
    } catch (err) {
      if (debug) console.info('Import error :', getErrorMessage(err));
    }
  },

  /** Create a single container and insert it in state record
   * @returns {string} ID of new container
   **/
  async [ActionTypes.CREATE] (context: Context, {
    typeId,
    name,
    content
  } : {
    typeId: TypeIdent,
    name: string,
    content?: Reference[]
  }) {
    const container = createContainer({ name, content });
    const id = container.id;

    context.commit(MutationTypes.INSERT, { typeId, container });

    await context.dispatch(ActionTypes.SAVE, typeId);

    return id;
  },

  /** Insert a reference into a container **/
  async [ActionTypes.INSERT_REFERENCE] (context: Context, {
    typeId,
    id,
    reference
  } : {
    typeId: TypeIdent,
    id: string,
    reference: Reference
  }) {
    const container: Container = insertReference({
      container: context.state.containers[typeId][id],
      reference,
    });

    context.commit(MutationTypes.INSERT, {
      typeId,
      container,
    });

    return context.dispatch(ActionTypes.SAVE, typeId);
  },

  /** Dump state **/
  async [ActionTypes.DEBUG] (context: Context) {
    console.info(JSON.stringify(context.state.containers, null, 2));
  },

  /** Delete container **/
  async [ActionTypes.DELETE] (context: Context, { typeId, id } : { typeId: TypeIdent, id: string }) {
    context.commit(MutationTypes.DELETE, { typeId, id });

    return context.dispatch(ActionTypes.SAVE, typeId);
  },

  /** Delete reference **/
  async [ActionTypes.DELETE_REFERENCE] (context: Context, {
    typeId,
    id,
    target
  } : {
  typeId: TypeIdent,
    id: string,
    target: string
  }) {
    const container: Container = deleteReference({
      container: context.state.containers[typeId][id],
      target
    });

    context.commit(MutationTypes.INSERT, {
      typeId,
      container,
    });

    return context.dispatch(ActionTypes.SAVE, typeId);
  },

  /** Delete all references in container record **/
  async [ActionTypes.DELETE_REFERENCES] (context: Context, {
    typeId,
    target
  } : {
    typeId: TypeIdent,
    target: string
  }) {
    const record: ContainerRecord = deleteReferences({
      record: context.state.containers[typeId],
      target,
    });

    context.commit(MutationTypes.SET_RECORD, {
      typeId,
      record,
    });

    return context.dispatch(ActionTypes.SAVE, typeId);
  }
};

const getters = <GetterTree<ContainerState, RootState>> {
  /** Get whether containers has been loaded **/
  hasLoaded(state: ContainerState): boolean {
    return state.hasLoaded;
  },

  /** Get all locations under group **/
  locationsInGroup(state: ContainerState) {
    return (id: string): Array<string> => {
      const group = state.containers[TypeIdent.GROUPS]?.[id];
      if (!group) {
        return [];
      }

      return group.content.map((locationReference) => locationReference.target);
    };
  },

  /** Get all unit IDs under group **/
  unitsInGroup(state: ContainerState, getters) {
    return (id: string): Array<number> => {
      const group = state.containers[TypeIdent.GROUPS]?.[id];
      if (!group) {
        return [];
      }

      return group.content.map((locationReference) => {
        return getters['unitsInLocation'](locationReference.target);
      }).flat();
    };
  },

  /** Get all unit IDs in location **/
  unitsInLocation(state: ContainerState) {
    return (id: string): Array<number> => {
      const location = state.containers[TypeIdent.LOCATIONS]?.[id];

      if (!location) {
        return [];
      }

      return location.content.map((deviceReference) => +deviceReference.target);
    };
  },

  /** Get all groups **/
  groups(state: ContainerState): Container[] {
    return Object.values(state.containers[TypeIdent.GROUPS]);
  },

  /** Get all locations **/
  locations(state: ContainerState): Container[] {
    return Object.values(state.containers[TypeIdent.LOCATIONS]);
  },

  /** Get all containers of a particular container type **/
  containers(state: ContainerState) {
    return (typeId: TypeIdent): Container[] => {
      return Object.values(state.containers[typeId]);
    };
  },

  /** Get specific container of particular container type **/
  getById(state: ContainerState) {
    return (typeId: TypeIdent, id: string): Container => {
      return state.containers[typeId]?.[id];
    };
  },

  /** Get all group names associated with a unit **/
  unitGroupNames(state: ContainerState, getters) {
    return (unitId: number) => {
      return getters.groups
        .map((group: Container) => ([ group.name, !!getters.unitsInGroup(group.id).includes(unitId) ]))
        .filter(([ , exist ]: [ string, boolean ]) => exist)
        .map(([ name, ]: [ string, boolean ]) => name);
    };
  },
};

export default <Module<ContainerState, RootState>>{
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
