import { Tag, DeviceTag, ConfigTag, LinkTag } from '@wisionmonorepo/wision-nfc-service/src/model/tag';
import { OnboardClientConfig } from '@wisionmonorepo/wision-nfc-service/src/model/config';
import useApiClient, { useRequestInit } from './apiClient';
import { ServiceBinding } from '@core/lib/serviceBindings';
import { UserRequest } from '@user-service/features/userProfile/model/userProfile';
import { OrganizationForm } from '@wisionmonorepo/wision-nfc-service/src/model/organizationForm';

export const useTagApi = (
  baseUrl: string,
  serviceBinding: ServiceBinding | null = null,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(serviceBinding?.fetch.bind(serviceBinding));
  const getRequestInit = useRequestInit(serviceBinding?.fetch, sessionCookie);

  const getDeviceTag = async (uuid: string) =>
    await apiClient<DeviceTag>(
      `${baseUrl}/api/v1/nfc/tag/${uuid}/view`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getConfigTag = async (uuid: string) =>
    await apiClient<ConfigTag>(
      `${baseUrl}/api/v1/nfc/tag/${uuid}/config`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getTag = async (uuid: string) =>
    await apiClient<Tag>(
      `${baseUrl}/api/v1/nfc/tag/${uuid}`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getLinkTag = async (uuid: string) =>
    await apiClient<LinkTag>(
      `${baseUrl}/api/v1/nfc/tag/${uuid}/view`,
      getRequestInit({
        method: 'GET',
      })
    );

  const sendUserConfig = async (uuid: string, onboardClientConfig: OnboardClientConfig) =>
    await apiClient(
      `${baseUrl}/api/v1/nfc/tag/${uuid}/config`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(onboardClientConfig),
      }),
      false
    );

  const userExists = async (uuid: string, email: string) =>
    await apiClient<{ userFound: boolean }>(
      `${baseUrl}/api/v1/nfc/user/${uuid}/${email}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
    );

  const createAccount = async (uuid: string, user: UserRequest, organization: OrganizationForm) => 
    await apiClient(
      `${baseUrl}/api/v1/nfc/user/${uuid}/createAccount`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user, organization })
      }),
      false
    );

  return {
    getDeviceTag,
    getConfigTag,
    getTag,
    getLinkTag,
    sendUserConfig,
    userExists,
    createAccount
  };
};
