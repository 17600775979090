/**
 * @overview Vuex module that exposes an interface to a state of dashboards
 */

import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/store/types';
import { i18n } from '@/core/i18n';
import { DashboardModel, staticDashboards, emptyDashboard, WidgetSettings } from '@/core/models/dashboard';
import clone from 'just-clone';
import { debug } from '@/../../../../config.js';
import { getErrorMessage } from '@/lib/helpers';
import { useDashboardApi } from '@wisionmonorepo/api';
import Timeout = NodeJS.Timeout;
import { identity } from 'lodash';

type Context = ActionContext<DashboardState, RootState>;

const dashboardApi = useDashboardApi(process.env.VUE_APP_WISION_API_URL || '/');

export interface DashboardState {
  dashboards: Record<string, DashboardModel>;
  hasLoaded: boolean;
  saveDelayTimer: { [id: string]: Timeout };
}

const getDefaultState = (): DashboardState => ({
  dashboards: {},
  hasLoaded: false,
  saveDelayTimer: {},
});

const state = getDefaultState();

export enum MutationTypes {
  SET_WIDGET_SETTING = 'SET_WIDGET_SETTING',
  SET = 'SET',
  DELETE = 'DELETE',
  SET_LOADED = 'SET_LOADED',
  CLEAR_STATE = 'CLEAR_STATE',
}

export enum ActionTypes {
  SAVE = 'SAVE',
  DELETE = 'DELETE',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  FETCH = 'FETCH',
  RESTORE = 'RESTORE',
}

const SAVE_DELAY = 2000;

/** Replace the i18n references in the dashboard model name with the actual translations   **/
const replaceTranslations = (dashboards: Record<string, DashboardModel>): Record<string, DashboardModel> => {
  const copy = clone(dashboards);

  Object.keys(copy).forEach((id: string) => copy[id].name = i18n.t(copy[id].name).toString());

  return copy;
};

const mutations = <MutationTree<DashboardState>>{
  /** Store dashboard model **/
  [MutationTypes.SET_WIDGET_SETTING] (
    state: DashboardState,
    { dashboard, widget, value }: { dashboard: DashboardModel, widget: string, value: WidgetSettings }
  ) {
    const currentWidget = dashboard.widgets[widget];
    if (!currentWidget?.settings) {
      Vue.set(currentWidget, 'settings', {});
    }

    if (currentWidget?.settings) {
      Object.entries(value).forEach(([key, value]) => {
        Vue.set(currentWidget.settings as Record<string, unknown>, key, value);
      });
    }
  },
  [MutationTypes.SET] (state: DashboardState, { id, dashboard }) {
    Vue.set(state.dashboards, id, dashboard);
  },

  /** Remove dashboard model **/
  [MutationTypes.DELETE] (state: DashboardState, id: string) {
    Vue.delete(state.dashboards, id);
  },

  /** Set dashboards is loaded **/
  [MutationTypes.SET_LOADED] (state: DashboardState) {
    state.hasLoaded = true;
  },

  /** Clear dashboard state **/
  [MutationTypes.CLEAR_STATE] (state: DashboardState) {
    Object.assign(state, getDefaultState());
  },
};

const actions = <ActionTree<DashboardState, RootState>>{
  /** Save dashboards to permanent storage **/
  async [ActionTypes.SAVE] ({ state }, id: string) {
    const dashboard = state.dashboards[id];

    if (!dashboard) {
      if (debug) console.info('Save: dashboard does not exist: ' + id);
      return;
    }

    if (debug) console.info('Saving dashboard: ' + id);

    // Save layout after a fixed delay to prevent flooding the API with save requests
    if (state.saveDelayTimer[id]) {
      clearTimeout(state.saveDelayTimer[id]);
    }

    // Pass copy of cached dashboard to prevent cache from being reassigned during delay
    const currentDashboard = clone(state.dashboards[id]);

    state.saveDelayTimer[id] = global.setTimeout(async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await dashboardApi.set(id, currentDashboard);
      delete(state.saveDelayTimer[id]);
    }, SAVE_DELAY);
  },

  /** Delete dashboard model **/
  async [ActionTypes.DELETE] (context: Context, id: string) {
    if (debug) console.info('Deleting dashboard: ' + id);
    await dashboardApi.delete(id);
    context.commit(MutationTypes.DELETE, id);
  },

  /** Add dashboard model to state **/
  async [ActionTypes.ADD] (context: Context, name: string) {
    const dashboard = clone(emptyDashboard);
    dashboard.name = name;

    const [{ id }] = await dashboardApi.create(name);

    context.commit(MutationTypes.SET, { id, dashboard });
    await context.dispatch(ActionTypes.SAVE, id);

    return id;
  },

  /** Update dashboard in state **/
  async [ActionTypes.UPDATE] (context: Context, payload: {
    id: string,
    parts: Partial<DashboardModel>,
  }) {
    const current = context.state.dashboards[payload.id];

    context.commit(MutationTypes.SET, {
      id: payload.id,
      dashboard: { ...current, ...payload.parts }
    });

    await context.dispatch(ActionTypes.SAVE, payload.id);
  },

  /** Restore dashboard model **/
  async [ActionTypes.RESTORE] (context: Context, id: string) {
    const staticDashboard = context.getters.dashboards[id]?.static;

    const dashboard = clone(staticDashboards[staticDashboard]);

    dashboard.name = i18n.t(staticDashboards[staticDashboard].name).toString();

    // Need to pre-commit an empty dashboard in order to get vue-grid-layout to pick up the changes
    context.commit(MutationTypes.SET, { id, dashboard: emptyDashboard });

    Vue.nextTick(() => {
      context.dispatch(ActionTypes.UPDATE, { id, parts: dashboard });
    });
  },

  /** Fetch dashboards from permanent storage **/
  async [ActionTypes.FETCH] (context: Context) {
    state.dashboards = {};

    try {
      const [dashboards] = await dashboardApi.getall();

      dashboards.forEach(({ id, content }) => {
        context.commit(MutationTypes.SET, { id, dashboard: content });

        if (debug) console.info(`Loading dashboard: ${id}: ${content.name}`);
      });
      const userStatic = dashboards.map(dashboard => dashboard.content?.static).filter(identity) as Array<string>;

      Object.entries(replaceTranslations(staticDashboards))
        .map(async ([staticId, dashboard]) => {
          if (!userStatic.includes(staticId)) {
            if (staticId === 'parking' && !context.rootGetters['user/showParking']) return;

            const [{ id }] = await dashboardApi.create(dashboard.name, staticId);
            context.commit(MutationTypes.SET, { id, dashboard: dashboard });

            if (debug) console.info(`Stored static dashboard: ${id}: ${dashboard.name}`);
          }
        })
        .filter(identity);
    } catch (err) {
      console.error(`Error fetching user dashboards: ${getErrorMessage(err)}`);
      context.commit(MutationTypes.SET_LOADED);
      throw err;
    }

    context.commit(MutationTypes.SET_LOADED);
  },
};

const getters = <GetterTree<DashboardState, RootState>>{
  /** All dashboard models **/
  dashboards(state: DashboardState): Record<string, DashboardModel> {
    return state.dashboards;
  },

  overview(state: DashboardState): DashboardModel {
    return Object.values(state.dashboards).filter(dashboard => dashboard.static === 'overview')?.[0];
  },

  overviewId(state: DashboardState): string | undefined {
    return Object.entries(state.dashboards)
      .find(([, dashboard]) => dashboard?.static === 'overview')?.[0];
  },
  /** Has dashboards been loaded? **/
  hasLoaded(state: DashboardState): boolean {
    return state.hasLoaded;
  },

  /** Get dashboard model by ID **/
  getById(state: DashboardState) {
    return (id: string): DashboardModel => {
      return state.dashboards[id];
    };
  },
};

export default <Module<DashboardState, RootState>>{
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
