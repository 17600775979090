/**
 * @overview Vuex module for user settings
 */
import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/store/types';
import { createPermissions, PermissionsModel } from '@/core/models/permissions';
import { ActionTypes as DashboardActions } from '@/core/store/modules/dashboard';
import { ActionTypes as DeviceTypes } from '@/core/store/modules/device';
import { ActionTypes as ContainerActions } from '@/core/store/modules/container';
import { ActionTypes as EventActions } from '@/core/store/modules/event-management';
import { ActionTypes as DeviceActions } from '@/core/store/modules/device';
import {
  login,
  logout,
  logoutSession,
  userSessions,
  deleteAvatar,
  uploadAvatar,
} from '@wisionmonorepo/api-client-v2/src/requests';
import { useUserApi } from '@wisionmonorepo/api';
import {
  UserDTO,
  DEFAULT_NOTIFICATION,
  DEFAULT_TIME_CONFIG
} from '@wisionmonorepo/wision-user-service/src/features/userProfile/dto/user';
import { SessionDTO } from '@wisionmonorepo/wision-core-service/src/core/model/dto/session';
import {
  LoginResponse,
  Customer,
  LoginAccess,
} from '@wisionmonorepo/api-client-v2/src/responses';
import { Toast } from '@/core/toast';
import { getErrorMessage } from '@/lib/helpers';
import { loadLanguageAsync } from '@/core/i18n';

enum ThemeMode {
  Dark = 'dark',
  Bright = 'bright',
}

const AVATAR_PATH = '/api/v3/user/avatar';

const userApi = useUserApi(process.env.VUE_APP_WISION_API_URL || '/');

export interface UserState {
  themeMode: ThemeMode;
  profile: UserDTO;
  selectedCustomers: Customer[];
  activeLanguage: string;
  permissions?: PermissionsModel;
  accessResponse?: LoginAccess;
  sessions: Array<SessionDTO>;
}

type Context = ActionContext<UserState, RootState>;

export enum MutationTypes {
  CLEAR_STATE = 'CLEAR_STATE',
  SET_TIME_FORMAT = 'SET_TIME_FORMAT',
  SET_NOTIFICATION_DESKTOP = 'SET_NOTIFICATION_DESKTOP',
  SET_NOTIFICATION_EMAIL = 'SET_NOTIFICATION_EMAIL',
  SET_DATE_FORMAT = 'SET_DATE_FORMAT',
  SET_DARK_MODE = 'SET_DARK_MODE',
  SET_BRIGHT_MODE = 'SET_BRIGHT_MODE',
  SET_ACCESS_RESPONSE = 'SET_ACCESS_RESPONSE',
  SET_SELECTED_CUSTOMERS = 'SET_SELECTED_CUSTOMERS',
  SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  SET_SESSIONS = 'SET_SESSIONS',
  SET_AVATAR = 'SET_AVATAR',
  SET_TITLE = 'SET_TITLE',
  SET_EMAIL = 'SET_EMAIL',
  SET_PHONE = 'SET_PHONE',
  SET_LOCATION = 'SET_LOCATION',
  SET_TIMEZONE = 'SET_TIMEZONE',
  SET_NAME = 'SET_NAME',
}

export enum ActionTypes {
  TOGGLE_THEME_MODE = 'TOGGLE_THEME_MODE',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  FETCH_PERMISSIONS = 'FETCH_PERMISSIONS',
  SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE',
  SET_SELECTED_CUSTOMERS = 'SET_SELECTED_CUSTOMERS',
  TOGGLE_CUSTOMER = 'TOGGLE_CUSTOMER',
  FETCH_SESSIONS = 'FETCH_SESSIONS',
  LOGOUT_SESSION = 'LOGOUT_SESSION',
  CLEAR_STATE = 'CLEAR_STATE',
  UPLOAD_AVATAR = 'UPLOAD_AVATAR',
  DELETE_AVATAR = 'DELETE_AVATAR',
  UPDATE_PROFILE_FIELD = 'UPDATE_PROFILE_FIELD',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  UPDATE_TIME_CONFIG = 'UPDATE_TIME_CONFIG',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
}

const defaultUserProfile: UserDTO = {
  username: '',
  name: '',
  title: '',
  email: '',
  phone: '',
  thumbnail: false,
  location: [0, 0],
  timeConfig: DEFAULT_TIME_CONFIG,
  notification: DEFAULT_NOTIFICATION,
  avatar: undefined,
  language: 'en'
};

export const getDefaultState = () => ({
  themeMode: ThemeMode.Dark,
  profile: defaultUserProfile,
  activeLanguage: 'en',
  selectedCustomers: [],
  accessResponse: undefined,
  permissions: undefined,
  sessions: [],
  avatarCacheKey: Date.now(),
  notifications: {
    desktop: true,
    email: false,
  },
});

const state: UserState = getDefaultState();

const mutations = <MutationTree<UserState>>{
  [MutationTypes.CLEAR_STATE] (state: UserState) {
    Object.assign(state, getDefaultState());
  },

  [MutationTypes.SET_DARK_MODE] (state: UserState) {
    state.themeMode = ThemeMode.Dark;
  },

  [MutationTypes.SET_TITLE] (state: UserState, value: string) {
    Vue.set(state.profile, 'title', value);
  },

  [MutationTypes.SET_EMAIL] (state: UserState, value: string) {
    Vue.set(state.profile, 'email', value);
  },

  [MutationTypes.SET_PHONE] (state: UserState, value: string) {
    Vue.set(state.profile, 'phone', value);
  },

  [MutationTypes.SET_LOCATION] (state: UserState, value: string) {
    Vue.set(state.profile, 'location', value);
  },

  [MutationTypes.SET_NAME] (state: UserState, value: string) {
    Vue.set(state.profile, 'name', value);
  },

  [MutationTypes.SET_AVATAR] (state: UserState, value: string) {
    Vue.set(state.profile, 'avatar', value);
  },

  [MutationTypes.SET_BRIGHT_MODE] (state: UserState) {
    state.themeMode = ThemeMode.Bright;
  },

  [MutationTypes.SET_ACTIVE_LANGUAGE] (state: UserState, language: string) {
    state.activeLanguage = language;
  },

  [MutationTypes.SET_PERMISSIONS] (state: UserState, permissions: PermissionsModel) {
    state.permissions = permissions;
  },

  [MutationTypes.SET_USER_PROFILE] (state: UserState, profile: UserDTO) {
    Vue.set(state, 'profile', profile);
  },

  [MutationTypes.SET_ACCESS_RESPONSE] (state: UserState, accessResponse: LoginAccess) {
    Vue.set(state, 'accessResponse', accessResponse);
    Vue.set(state, 'selectedCustomers',  accessResponse.Customers);
  },

  [MutationTypes.SET_SELECTED_CUSTOMERS] (state: UserState, customers: Customer[]) {
    state.selectedCustomers = customers;
  },

  [MutationTypes.SET_SESSIONS] (state: UserState, sessions: Array<SessionDTO>) {
    state.sessions = sessions;
  },
};

const actions = <ActionTree<UserState, RootState>> {
  async [ActionTypes.TOGGLE_THEME_MODE] (context: Context) {
    if (context.state.themeMode === ThemeMode.Bright) {
      context.commit(MutationTypes.SET_DARK_MODE);
    } else {
      context.commit(MutationTypes.SET_BRIGHT_MODE);
    }

    return context.state.themeMode;
  },

  async [ActionTypes.USER_LOGIN] (
    context: Context,
    credentials: { username: string, password: string }
  ): Promise<LoginResponse> {
    await context.dispatch('clearState', null, { root: true });

    const loginResponse = await login(credentials.username, credentials.password);

    context.commit(MutationTypes.SET_ACCESS_RESPONSE, loginResponse.access);
    context.commit(MutationTypes.SET_USER_PROFILE, loginResponse.profile);

    await Promise.all([
      context.dispatch(ActionTypes.SET_ACTIVE_LANGUAGE, loginResponse.profile.language),
      context.dispatch(ActionTypes.FETCH_PERMISSIONS, loginResponse.access),
      context.dispatch(`dashboard/${DashboardActions.FETCH}`, null, { root: true }),
      context.dispatch(`eventManagement/${EventActions.ENABLE_ALARM_LISTENER}`, null, { root: true }),
      context.dispatch(`eventManagement/${EventActions.FETCH_ALL}`, null, { root: true }),
      context.dispatch(`device/${DeviceTypes.FETCH_DEVICE_TYPES}`, null, { root: true }),
      context.dispatch(`container/${ContainerActions.FETCH}`, null, { root: true }),
      context.dispatch(`device/${DeviceTypes.FETCH_CURRENT_DEVICES}`, null, { root: true }),
    ]);

    return loginResponse;
  },

  async [ActionTypes.USER_LOGOUT] (context: Context): Promise<void> {
    await Promise.all([
      context.dispatch(`container/${ContainerActions.RESET}`,null, { root: true }),
      context.dispatch(`eventManagement/${EventActions.DISABLE_ALARM_LISTENER}`, null, { root: true }),
      context.dispatch('clearState', null, { root: true })
    ]);

    await logout();

    document.cookie = 'session' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },

  async [ActionTypes.CLEAR_STATE] (context: Context): Promise<void> {
    context.commit(MutationTypes.CLEAR_STATE);
  },

  async [ActionTypes.UPDATE_PASSWORD] (
    context: Context,
    param: { currentPassword: string, newPassword: string }
  ): Promise<boolean> {
    try {
      const [, error] = await userApi.setPassword(param.currentPassword, param.newPassword);
      return !error;
    } catch (error) {
      return false;
    }
  },

  async [ActionTypes.UPDATE_PROFILE_FIELD] (context: Context, param: { field: string, value: string }): Promise<void> {
    switch (param.field) {
    case 'title':
      context.commit(MutationTypes.SET_TITLE, param.value);
      break;
    case 'email':
      context.commit(MutationTypes.SET_EMAIL, param.value);
      break;
    case 'phone':
      context.commit(MutationTypes.SET_PHONE, param.value);
      break;
    case 'location':
      context.commit(MutationTypes.SET_LOCATION, param.value);
      break;
    case 'name':
      context.commit(MutationTypes.SET_NAME, param.value);
      break;
    default:
      console.error('Unknown field', param.field);
      return;
    }

    const state = context.state;
    
    await userApi.updateUserProfil({
      username: state.profile.username,
      email: state.profile.email,
      fullname: state.profile.name,
      title: state.profile.title,
      phone: state.profile.phone,
      avatar: state.profile.avatar,
      notification_email: state.profile.notification.email ? 1 : 0,
      notification_desktop: state.profile.notification.desktop ? 1 : 0,
      time_12h: 0,
      date_format: state.profile.timeConfig.dateFormat,
    });
  },

  async [ActionTypes.UPLOAD_AVATAR] (context: Context, file: File): Promise<void> {
    try {
      if (file) {
        const res = await uploadAvatar(file);
        context.commit(MutationTypes.SET_AVATAR, res);
      }
      else
        throw (new Error('Missing parameters'));
    } catch (error) {
      Toast.createToast(getErrorMessage(error), 'danger');
    }
  },

  async [ActionTypes.DELETE_AVATAR] (context: Context): Promise<void> {
    try {
      await deleteAvatar();

      context.commit(MutationTypes.SET_AVATAR, undefined);
    } catch (error) {
      Toast.createToast(getErrorMessage(error), 'danger');
    }
  },

  [ActionTypes.FETCH_PERMISSIONS] (context: Context, loginAccess: LoginResponse['access']): void {
    if (!context.state.permissions) {
      const permissions = createPermissions(loginAccess);

      context.commit(MutationTypes.SET_PERMISSIONS, permissions);
    }
  },

  async [ActionTypes.SET_ACTIVE_LANGUAGE] (context: Context, language: string): Promise<void> {
    context.commit(MutationTypes.SET_ACTIVE_LANGUAGE, language);
    await loadLanguageAsync(language);
    await userApi.updateUserProfil({ language });
  },

  async [ActionTypes.LOGOUT_SESSION] (context: Context, id: string): Promise<void> {
    logoutSession(id);
  },

  async [ActionTypes.TOGGLE_CUSTOMER] (context: Context, customer: Customer): Promise<void> {
    const filter: (item: Customer) => boolean = (item: Customer): boolean => item.CustomerID !== customer.CustomerID;
    let customers: Customer[] = context.state.selectedCustomers;
    if (customers.filter(filter).length === customers.length) {
      customers.push(customer);
    } else {
      customers = customers.filter(filter);
    }

    await context.dispatch(ActionTypes.SET_SELECTED_CUSTOMERS, customers);
  },

  async [ActionTypes.SET_SELECTED_CUSTOMERS] (context: Context, customers: Customer[]): Promise<void> {
    context.commit('SET_SELECTED_CUSTOMERS', customers);
    context.dispatch(`device/${DeviceActions.FETCH_CURRENT_DEVICES}`, true, { root: true });
  },

  async [ActionTypes.FETCH_SESSIONS] (context: Context) {
    const sessions = await userSessions();

    context.commit(MutationTypes.SET_SESSIONS, sessions);
  },
};

const getters = <GetterTree<UserState, RootState>> {
  themeMode(state: UserState): string {
    return state.themeMode;
  },

  avatarPath(state: UserState): string | undefined {
    return state.profile.avatar
      ?  `${process.env.VUE_APP_WISION_API_URL}${AVATAR_PATH}/${state.profile.avatar}`
      : undefined;
  },

  profile(state: UserState): UserDTO {
    return state.profile;
  },

  sessions(state: UserState): Array<SessionDTO> {
    return state.sessions;
  },

  userName(state: UserState) {
    return state.profile.username;
  },

  initials(state: UserState) {
    const [firstname, ...rest] = state.profile.name.split(' ');
    const lastname = rest?.length ? rest.join(' ') : '';

    return `${firstname?.[0] || ''} ${lastname?.[0] || ''}`;
  },

  fullName(state: UserState) {
    return state.profile.name;
  },

  access(state: UserState): LoginAccess | undefined {
    return state?.accessResponse;
  },

  activeLanguage(state: UserState): string {
    return state.activeLanguage;
  },

  selectedCustomerIds(state: UserState): number[] {
    return state
      .selectedCustomers
      .map((customer: Customer) => customer.CustomerID);
  },

  selectedGisCustomers(state: UserState): number[] {
    const selectedCustomerIds = state.selectedCustomers.map((c) => c.CustomerID);

    return state.permissions?.gisCustomers.filter((c) => selectedCustomerIds.includes(c)) || [];
  },

  showParking(state: UserState): boolean {
    const selectedCustomerIds = state.selectedCustomers.map((c) => c.CustomerID);

    return !!state.permissions?.parkingCustomers.some((c) => selectedCustomerIds.includes(c));
  },
};

export default <Module<UserState, RootState>>{
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
