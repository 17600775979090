import { getUnitGroups, getUnitsByPos } from '@wisionmonorepo/api-client-v1/src/requests';
import { GetUnitGroupsResponse, GetUnitsByPosResponse } from '@wisionmonorepo/api-client-v1/src/responses';
import {
  createContainer,
  createReference,
  ReferenceType,
  Container,
  ContainerRecord,
} from '@/core/models/container';

export const importGroup = async (
  customerId: number,
  groupName: string,
): Promise<[ContainerRecord, ContainerRecord]> => {
  const groups: Record<string, Container> = {};

  if (!(customerId && groupName)) {
    throw new Error('Missing parameters');
  }

  const groupsResponse: GetUnitGroupsResponse[] = await getUnitGroups({ customerId });

  const locations = await createLocations(customerId, groupsResponse);

  const locationReferences = Object.entries(locations)
    .map(([id, location]) => createReference({
      name: location.name,
      type: ReferenceType.Location,
      target: id,
    }));

  const group = createContainer({ name: groupName, content: locationReferences });
  groups[group.id] = group;

  return [groups, locations];
};

const createLocations = async (
  customerId: number,
  groupsResponse: GetUnitGroupsResponse[],
): Promise<ContainerRecord> => {
  const locations: Record<string, Container> = {};

  for (const group of groupsResponse) {
    const units = await getUnitsInGroup(customerId, group.GroupId);
    if (!units.length) continue;

    const unitReferences =
      units.map(
        (unit: GetUnitsByPosResponse) => createReference({
          name: unit.UnitName,
          type: ReferenceType.Unit,
          target: unit.UnitID
        }));

    const location = createContainer({ name: group.Description, content: unitReferences });
    locations[location.id] = location;
  }

  return locations;
};

const getUnitsInGroup = async (customerId: number, groupId: number): Promise<GetUnitsByPosResponse[]> => {
  return await getUnitsByPos({
    customerIds: [ customerId ],
    unitGroupIds: [ groupId ],
  });
};
