/**
 * @overview DB entity data model
 */

export type DbRecord<T extends DbEntity> = Record<string, T>;

export enum EntityKind {
  Task = 'task',
  Event = 'event',
  Notification = 'notification',
}

export interface DbEntity {
  id: string;
  created: Date;
  version: string;
  kind: EntityKind;
}
